import { arrayExtensions, stringExtensions } from "lib-common";
import { Alert, Col, DeleteButton, Divider, EditButton, Icons, Row, Show, Space, Tag, Typography } from "@pankod/refine-antd";
import { BaseRecord, CanAccess, IResourceComponentsProps, useList, useNavigation, useShow } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { ShowUserCard } from "components/profile/showUserCard";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IMemberGroup } from "interfaces";
import { DATAPROVIDER_DELETE, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, FETCH_ACTION, RESOURCE_PATH, arrayJoinString } from "scripts/site";

export const GroupShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IMemberGroup>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const twoColumnLaytout = { xs: { span: 24 }, sm: { span: 24 }, md: { span: 12 }, lg: { span: 12 }, xl: { span: 12 }, xxl: { span: 12 } };
  const rowGutter = { xs: 8, sm: 16, md: 24, lg: 32 };
  const record = data?.data as IMemberGroup;

  const { data: groupMembersData, isLoading: isMembersLoading } = useList<BaseRecord>({
    dataProviderName: DATAPROVIDER_READ,
    resource: `${RESOURCE_PATH.GROUPS}/${record?.id}/${FETCH_ACTION}`,
    config: {
      filters: [
        {
          field: "operation",
          operator: "eq",
          value: "groupMembersList"
        }
      ]
    },
    queryOptions: {
      enabled: (!isLoading)
    }
  });
  const { list } = useNavigation();
  const groupMembers = groupMembersData?.data as unknown as string[];

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        title: record?.id,
        tags: <>{record?.stateManager?.state ? <Tag style={{ marginLeft: 6 }} color="green">{stringExtensions.capitalize(record?.stateManager?.state)}</Tag> : <></>}</>
      }}
      headerButtons={
        () => (
          <>
            {record &&
              <>
                <CanAccess key={`${RESOURCE_PATH.GROUPS}-edit`} resource={RESOURCE_PATH.GROUPS.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
                  <EditButton></EditButton>
                </CanAccess>
                <CanAccess key={`${RESOURCE_PATH.GROUPS}-delete`} resource={RESOURCE_PATH.GROUPS.toLowerCase()} action="delete" params={{ dataProviderName: DATAPROVIDER_DELETE, id: record.id }}>
                  {!record?.CanNotDelete &&
                    <DeleteButton
                      dataProviderName={DATAPROVIDER_DELETE}
                      onSuccess={() => {
                        list(RESOURCE_PATH.GROUPS, "replace")
                      }}
                    ></DeleteButton>

                  }
                </CanAccess>
              </>
            }
          </>
        )
      }
    >
      {
        record?.CanNotDelete &&
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message="Group deletion is locked"
            description={record?.notes}
            type="info"
            icon={<Icons.MinusCircleOutlined />}
            showIcon
            style={{ marginBottom: "1.2em" }}
          />
        </Space>
      }
      <Row gutter={rowGutter}>
        <Col className="gutter-row" {...twoColumnLaytout}>
          <Title level={5}>Group Identifier (Alias)</Title>
          <Text copyable>{record?.id}</Text>

          <Title level={5}>Group description</Title>
          <Text copyable>{record?.name}</Text>
        </Col>
        <Col className="gutter-row" {...twoColumnLaytout}>
          <Title level={5}>Group Type</Title>
          <Text copyable>{stringExtensions.capitalize(record?.groupType)}</Text>

          <Title level={5}>Group Admins</Title>
          <Space wrap split={<Divider type="vertical" />}>
            {arrayExtensions.validateArray(record?.admins ?? []) ? record?.admins?.map((e, i) => <ShowUserCard key={`sales-${i}`} id={e}></ShowUserCard>) : "-- None --"}
          </Space>
        </Col>
      </Row>
      <Row gutter={rowGutter}>
        <Col xl={24} lg={24} xs={24}>
          <Title level={5} style={{ marginTop: "1.2em" }}>{`${groupMembers?.length || ""} Group Members`}</Title>
          {isMembersLoading ? <RenderSpinner message="Loading Members ..." /> :
            <div className="grpmmbrs">
              <Text copyable disabled>{groupMembers ? arrayJoinString(groupMembers, "; ") : "No Members added to this group"}</Text>
            </div>
          }
          <Tag style={{ marginTop: "1.2em" }} color="blue">Please use copy icon to copy the members list</Tag>
        </Col>
      </Row>
      <Divider></Divider>
      <div style={{ marginTop: 15 }}>
        <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
      </div>
    </Show >
  );
};
