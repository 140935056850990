import { Button, Col, Form, FormProps, Icons, Input, Row, Select, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IListData, ILookup, IUserMetadata } from "interfaces";
import { stringExtensions } from "lib-common";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, SELECTION_LISTS, STALE_DURATION } from "scripts/site";

export const UserFilter: React.FC<{ formProps: FormProps, mode: string }> = (props) => {
    const { isLoading, data: metadata } = useOne<IUserMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.USERS,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: countryProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.COUNTRY,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            }
        ],
        fetchSize: 100
    });

    const { selectProps: locationProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LOCATION,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 100
    });

    const { selectProps: roleProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.ROLE,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 50
    });

    const { data: hobbiesList } = useOne<IListData>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LIST,
        id: SELECTION_LISTS.HOBBIES
    });

    const { data: technicalExpertiseList } = useOne<IListData>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LIST,
        id: SELECTION_LISTS.TECHNICAL_EXPERTISE
    });

    const { data: functionalExpertiseList } = useOne<IListData>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LIST,
        id: SELECTION_LISTS.FUNCTIONAL_EXPERTISE
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label={"Search"}
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Users"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Users"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    {props?.mode === "user" &&
                        <Col xs={24} xl={24} md={8}>
                            <Form.Item
                                label="User Type"
                                name="userType"
                            >
                                <Select
                                    allowClear
                                    mode="multiple"
                                    placeholder="User Type"
                                >
                                    {metaConfig?.userTypes.sort().map((m, i) => (
                                        <Select.Option value={m} key={i}>{stringExtensions.capitalize(m)}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Status"
                            name="state"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Status"
                            >
                                {metadata?.data?.states.map((m, i) => (
                                    <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="User Role"
                            name="role"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="User Role"
                                {...roleProps}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="User Country"
                            name="country"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Country"
                                {...countryProps}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="User Location"
                            name="location"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Location"
                                {...locationProps}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Hobbies"
                            name="hobbies"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Hobbies"
                            >
                                {hobbiesList?.data?.data.map((m, i) => (
                                    <Select.Option value={m} key={i}>{stringExtensions.capitalize(m)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Technical Expertise"
                            name="technicalExpertiseAreas"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Technical Expertise Areas"
                            >
                                {technicalExpertiseList?.data?.data.map((m, i) => (
                                    <Select.Option value={m} key={i}>{stringExtensions.capitalize(m)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Functional Expertise"
                            name="functionalExpertiseAreas"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Functional Expertise Areas"
                            >
                                {functionalExpertiseList?.data?.data.map((m, i) => (
                                    <Select.Option value={m} key={i}>{stringExtensions.capitalize(m)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Contextual Master"
                            name="contextualMaster"
                        >
                            <Select
                                allowClear
                                placeholder="Contextual Master"
                            >
                                <Select.Option value={true} key="ky-ctxmstr-1">Yes</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                Apply Filter(s)
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => { props.formProps.form?.resetFields() }}
                            >
                                Clear Filter(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}