import { arrayExtensions, stringExtensions } from "lib-common";
import { Alert, Button, Col, DeleteButton, Divider, EditButton, Icons, QRCode, Row, Show, Space, Tabs, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { RoomAvailability } from "components/spaces/roomAvailability";
import { LookupButton } from "components/utils/lookupButton";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, ILocations, IRoom } from "interfaces";
import { DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, HELIUM_LOGO_PATH, RESOURCE_PATH, STALE_DURATION, greyStyle } from "scripts/site";

export const RoomShow: React.FC<IResourceComponentsProps> = () => {
    //Get user info
    const { queryResult, showId } = useShow<IRoom>({
        dataProviderName: DATAPROVIDER_READ,
    });

    const { data, isLoading } = queryResult;
    const { Title, Text } = Typography;
    const record = data?.data as IRoom;

    const { data: locationData } = useOne<ILocations>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LOCATION,
        id: record?.locationId ?? "",
        queryOptions: {
            enabled: !!record?.locationId,
            staleTime: STALE_DURATION
        }
    });

    const timeZone = locationData?.data?.timezone;

    function downloadQRCode() {
        const canvas = document.getElementById('qrcode')?.querySelector<HTMLCanvasElement>('canvas');
        if (canvas) {
            const url = canvas.toDataURL("image/jpg");
            const a = document.createElement('a');
            a.download = record?.name + 'QRCode.jpg';
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };

    const tabItems = [];
    if (record?.offline !== true) {
        tabItems.push({
            label: (<span><Icons.CalendarOutlined />Availability</span>),
            key: "availability",
            children: <>
                <RoomAvailability roomId={showId as string} locationData={locationData?.data} />
                {/* {record
                    ? <RoomAvailability roomId={record?.id} locationData={locationData?.data} />
                    : <RenderSpinner message="Loading..." />
                } */}
            </>
        });
    }

    tabItems.push({
        label: (<span><Icons.InfoCircleOutlined />Info</span>),
        key: "info",
        children: <>
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    <Title style={{ marginTop: 15 }} level={5}>Room Administrators</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.spaces?.administrators ?? []) ? record?.spaces?.administrators?.map((e, i) => <ShowUserCard key={`admin-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>
                    <Title style={{ marginTop: 15 }} level={5}>Power User Group</Title>
                    <Text>{record?.spaces?.powerUserGroup ?? "---"}</Text>
                </Col>
                <Col xl={12} lg={24} xs={24}>
                    <Title style={{ marginTop: 15 }} level={5}>Power Users</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.spaces?.powerUsers ?? []) ? record?.spaces?.powerUsers?.map((e, i) => <ShowUserCard key={`pu-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>
                </Col>
            </Row>
            {record?.remarks && <>
                <Title style={{ marginTop: 15 }} level={5}>Remarks</Title>
                <Text>{record?.remarks ?? "---"}</Text>
            </>}
            {/* <Divider></Divider> */}
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    {record?.spaces?.reservationRules &&
                        <>
                            <Divider dashed>Reservation Rules</Divider>
                            <Title level={5}>Timings</Title>
                            <Text>{"Begin time: " + (record?.spaces?.reservationRules?.beginTime ?? "Not set")}</Text>
                            <br />
                            <Text>{"End time: " + (record?.spaces?.reservationRules?.endTime ?? "Not set")}</Text>

                            <Title style={{ marginTop: 15 }} level={5}>Duration</Title>
                            <Text>{"Minimum: " + (record?.spaces?.reservationRules?.minReservationDuration ?? "---") + " mins"}</Text>
                            <br />
                            <Text>{"Maximum: " + (record?.spaces?.reservationRules?.maxReservationDuration ?? "---") + " mins"}</Text>

                            <Title style={{ marginTop: 15 }} level={5}>Align Reservations</Title>
                            <Text>{(record?.spaces?.reservationRules?.alignReservations === true ? (record?.spaces?.reservationRules?.alignmentBoundary + " mins") : "No")}</Text>

                            <Title style={{ marginTop: 15 }} level={5}>Advance Days</Title>
                            <Text>{(record?.spaces?.reservationRules?.advanceDays ?? "---")}</Text>

                        </>}
                </Col>
                <Col xl={12} lg={24} xs={24}>
                    <Divider dashed>Accomodations</Divider>
                    {record?.accomodations && <>
                        {Object.keys(record.accomodations).map((key, index) =>
                            <>
                                <Title style={{ marginBottom: 15 }} key={"title" + index} level={5}>
                                    {stringExtensions.capitalize(key)}
                                </Title>
                                <Text key={"text" + index}>
                                    {record?.accomodations[key] ?? "---"}
                                </Text>
                            </>
                        )}
                    </>}
                </Col>
            </Row>
        </>
    });
    tabItems.push({
        label: (<span><Icons.QrcodeOutlined />QR code</span>),
        key: "qr",
        children:
            <div id="qrcode">
                <QRCode
                    value={window.location.href.split("?")[0]}
                    icon={HELIUM_LOGO_PATH}
                />
                <Button style={{ marginTop: 20, width: 160 }} icon={<Icons.DownloadOutlined />} type="primary" onClick={downloadQRCode}>
                    Download
                </Button>
            </div>
    });

    return (
        <Show
            isLoading={isLoading}
            headerProps={{
                title: record?.name,
                tags: <>{record?.private && <Tag color="warning">Private</Tag>}</>,
                extra: showId &&
                    <>
                        <CanAccess key={RESOURCE_PATH.ROOM + "-edit"} resource={RESOURCE_PATH.ROOM.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
                            <EditButton></EditButton>
                        </CanAccess>
                        <CanAccess key={RESOURCE_PATH.ROOM + "-delete"} resource={RESOURCE_PATH.ROOM.toLowerCase()} action="delete" params={{ id: showId, dataProviderName: DATAPROVIDER_DELETE }}>
                            <DeleteButton dataProviderName={DATAPROVIDER_DELETE}></DeleteButton>
                        </CanAccess>
                    </>
            }}
        >
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    <Title level={5}>Room Id</Title>
                    <Text>{showId}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>Name</Title>
                    <Text>{record?.name ?? "---"}</Text>
                    <br />
                    <LookupButton id={record?.countryId || ""} resource={RESOURCE_PATH.COUNTRY} noClick></LookupButton>
                    <Text style={greyStyle}>{" / "}</Text>
                    <LookupButton id={record?.locationId || ""} resource={RESOURCE_PATH.LOCATION} noClick></LookupButton>
                    <Text style={greyStyle}>{" / "}</Text>
                    <LookupButton id={record?.buildingId || ""} resource={RESOURCE_PATH.BUILDING} noClick></LookupButton>
                    <Text style={greyStyle}> {" / "}</Text>
                    <LookupButton id={record?.zoneId || ""} resource={RESOURCE_PATH.ZONE} noClick></LookupButton>
                </Col>
                <Col xl={12} lg={24} xs={24}>
                    <Title level={5}>Occupancy</Title>
                    <Text>{(record?.minOccupancy ?? 0) + " (Min) - " + (record?.maxOccupancy ?? 0) + " (Max)"}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>Timezone</Title>
                    <Text>{timeZone ?? "---"}</Text>
                </Col>
            </Row>
            {record?.offline &&
                <Alert
                    message="Room is offline. Reservation is not allowed."
                    description={record?.remarks}
                    type="warning"
                    showIcon
                    style={{ marginTop: 20 }}
                />}

            <Tabs
                style={{ marginTop: 20 }}
                defaultActiveKey={"availability"}
                //defaultActiveKey={(record?.offline !== true) ? "info" : "availability"}
                items={tabItems}
            />
            <Divider></Divider>
            <div style={{ marginTop: 15 }}>
                <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
            </div>
        </Show>
    )
}