import { arrayExtensions } from "lib-common";
import {
  Button,
  DateField,
  DatePicker,
  DeleteButton,
  FilterDropdown,
  FilterDropdownProps, getDefaultSortOrder, Icons, Input, List, Modal, Popconfirm, Table, Tag, TextField, Typography, useTable
} from "@pankod/refine-antd";
import { CanAccess, CrudFilter, CrudFilters, IResourceComponentsProps, LogicalFilter, useDelete, useNavigation, useOne, useResourceWithRoute, useUpdate } from "@pankod/refine-core";
import { EngagementSubMenu } from "components/engagement/subMenu";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import dayjs, { Dayjs } from "dayjs";
import { IAssociateMapping, ILookup } from "interfaces";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_CREATE, DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, getQueryStringParams, RESOURCE_PATH } from "scripts/site";

export const AllocationList: React.FC<IResourceComponentsProps> = () => {

  const { push } = useNavigation();
  const { search } = useLocation();
  const { engagementId } = getQueryStringParams(search);

  const assignedFilter: CrudFilter[] = (engagementId ? [{
    field: "engagementId",
    operator: "eq",
    value: engagementId
  }] : []);

  const initialFilters: CrudFilters = [{
    field: "userId",
    operator: "contains",
    value: ""
  }];

  const { tableProps, sorter, tableQueryResult } = useTable<IAssociateMapping>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],

    initialFilter: initialFilters,
    permanentFilter: assignedFilter,
    syncWithLocation: false
  });
  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });

  const { data: enggName } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ENGAGEMENT,
    id: engagementId,
    queryOptions: {
      enabled: !!engagementId
    },
  });

  const resourceWithRoute = useResourceWithRoute();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<IAssociateMapping[]>([]);
  const [maxStartDate, setMaxStartDate] = useState<Dayjs | undefined>();
  const hasSelected = selectedRowKeys.length > 0;
  const { mutate: deleteMapping, isLoading: deleteIsLoading } = useDelete<IAssociateMapping>();
  const { mutate: setEndDateMapping, isLoading: endDateIsLoading } = useUpdate<IAssociateMapping>();

  const deleteSelectedItems = () => {
    selectedRowKeys.forEach((allocationId) => {
      deleteMapping({
        resource: RESOURCE_PATH.ASSOCIATEMAPPING,
        dataProviderName: DATAPROVIDER_DELETE,
        id: allocationId,
        successNotification: { key: "notificationKey", message: "Successful", description: `Successfully deleted associate mappings`, type: "success" }
      });
    })
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setMaxStartDate(undefined);
  };

  const setEndDate = () => {
    selectedRowKeys.forEach((allocationId) => {
      setEndDateMapping({
        resource: RESOURCE_PATH.ASSOCIATEMAPPING,
        dataProviderName: DATAPROVIDER_UPDATE,
        id: allocationId,
        successNotification: { key: "notificationKey", message: "Successful", description: `Successfully updated end date`, type: "success" },
        values: { endDate: modalEndDate ? modalEndDate : "" }
      });
    })
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setMaxStartDate(undefined);
    setModalVisible(false);
  };

  const totalRecords = tableQueryResult?.data?.total ?? 0;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEndDate, setModalEndDate] = useState<Dayjs | null>();

  return (
    <div className="">
      <List
        title={enggName?.data?.name}
        headerProps={{
          extra: <>
            {/* Need to pass the engagementId as a query parameter to build a dummy object for RBAC check */}
            <CanAccess key={RESOURCE_PATH.ASSOCIATEMAPPING} resource={RESOURCE_PATH.ASSOCIATEMAPPING.toLowerCase()} action="create"
              params={{ dataProviderName: DATAPROVIDER_CREATE, id: "1", engagementId: engagementId }}>
              <Button
                icon={<Icons.PlusOutlined />}
                onClick={() => push(`/${resourceWithRoute(RESOURCE_PATH.ASSOCIATEMAPPING).route}/create?engagementId=${engagementId}`)}
              >
                Map Associates
              </Button>
            </CanAccess>

            <Button
              loading={endDateIsLoading}
              icon={<Icons.CalendarOutlined />}
              disabled={!hasSelected}
              onClick={() => {
                const sortedRows = arrayExtensions.sortBy("startDate", selectedRows);
                setMaxStartDate(dayjs(sortedRows[sortedRows.length - 1].startDate).add(1, "day"));
                setModalVisible(true);
              }}
            >
              Set End Date
            </Button>

            <Popconfirm
              key="delete"
              okText="Delete"
              cancelText="Cancel"
              okType="danger"
              title="Are you sure?"
              onConfirm={deleteSelectedItems}
            >
              <Button
                danger
                loading={deleteIsLoading}
                icon={<Icons.DeleteOutlined />}
                disabled={!hasSelected}
              >
                Delete
              </Button>
            </Popconfirm>
          </>,
          //tags: <Tag>{(tableQueryResult?.data?.total ?? 0) + " Contracts"}</Tag>,
          tags: <Tag color={"default"}>
            {tableQueryResult.isLoading ? "Loading" : totalRecords} Associate{totalRecords !== 1 && "s"}
          </Tag>
        }}
      >
        <EngagementSubMenu engagementId={engagementId} selectedResource={RESOURCE_PATH.ASSOCIATEMAPPING} ></EngagementSubMenu>
        <Table {...tableProps}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedRowKeys: React.Key[], selectedRows) => {
              setSelectedRowKeys(selectedRowKeys);
              setSelectedRows(selectedRows);
            },
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,
            ],
          }}
          style={{ marginTop: 24 }}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            position: ["bottomRight"],
            showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
          }}
          onChange={(p, f, s, e) => {
            initialFilters.forEach(fl => {
              // Set the value to blank string if filter value is undefined.
              // This will avoid filter getting removed from URL and getting reset to equal operator.
              let tempFl = fl as LogicalFilter;
              if (tempFl && !f[tempFl.field]) {
                f[tempFl.field] = [""];
              }
            });
            defaultOnChange(p, f, s, e);
          }}
        >
          <Table.Column
            key="userId"
            dataIndex="userId"
            title="Associate"
            className="mouseHand"
            defaultSortOrder={getDefaultSortOrder("userId", sorter)}
            sorter
            render={(value: string) => <ShowUserCard id={value} />}
            // Add text filter
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )} />

          <Table.Column
            dataIndex="startDate"
            key="startDate"
            title="Start Date"
            render={(value) => value && <DateField format="ll" value={value} />}
            defaultSortOrder={getDefaultSortOrder("startDate", sorter)}
            sorter
            className="mouseHand" />

          <Table.Column
            dataIndex="endDate"
            key="endDate"
            title="End Date"
            render={(value) => value && <DateField format="ll" value={value} />}
            defaultSortOrder={getDefaultSortOrder("endDate", sorter)}
            sorter
            className="mouseHand" />

          <Table.Column
            dataIndex="billRateUSD"
            key="billRateUSD"
            title="Bill Rate USD"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("billRateUSD", sorter)}
            sorter
            className="mouseHand"
            // Add text filter          
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )} />


          <Table.Column
            dataIndex="percentAllocation"
            key="percentAllocation"
            title="Percent"
            render={(value) => <TextField value={value} />}
            defaultSortOrder={getDefaultSortOrder("percentAllocation", sorter)}
            sorter
            className="mouseHand"
            // Add text filter          
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )} />

          <Table.Column
            key="role"
            dataIndex="role"
            title="Role"
            className="mouseHand"
            defaultSortOrder={getDefaultSortOrder("role", sorter)}
            sorter
            render={(value) => <TextField value={value} />}
            // Add text filter
            filterDropdown={(props) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )} />

          <Table.Column
            dataIndex="locationId"
            key="locationId"
            title="Location"
            render={(value: string) => <TextField value={value} />}
            sorter
            defaultSortOrder={getDefaultSortOrder("locationId", sorter)}

            // Add text filter                
            filterDropdown={(props: FilterDropdownProps) => (
              <FilterDropdown {...props}>
                <Input />
              </FilterDropdown>
            )} />

          <Table.Column
            dataIndex="updatedAt"
            key="updatedAt"
            title="Last Updated"
            render={displayRelativeDate}
            defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
            sorter
            className="mouseHand"
          />
          <Table.Column
            dataIndex="updatedBy"
            key="updatedBy"
            title="Updated By"
            render={(value: string) =>
              <ShowUserCard id={value} />
            }
            className="mouseHand"
          />
          <Table.Column<IAssociateMapping>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <DeleteButton resourceNameOrRouteName={RESOURCE_PATH.ASSOCIATEMAPPING} dataProviderName={DATAPROVIDER_DELETE} size="small" recordItemId={record.id} />
            )}
          />
        </Table>
      </List>
      <Modal
        open={modalVisible}
        title={"Set End Date"}
        maskClosable={true}
        closable={true}
        okText={"Save"}
        cancelText="Cancel"
        onCancel={() => setModalVisible(false)}
        onOk={setEndDate}
      >
        <DatePicker picker="date"
          placeholder="No End Date"
          allowClear
          style={{ width: 280 }}
          value={modalEndDate}
          onChange={(date) => setModalEndDate(date)}
          disabledDate={(selDate) => selDate && !!maxStartDate && selDate <= maxStartDate}
        />
      </Modal>
    </div>
  );
};
