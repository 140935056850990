import { stringExtensions } from "lib-common";
import { Button, Col, DatePicker, Form, Icons, Input, InputNumber, notification, Radio, Result, Row, Select, Spin, useSelect } from "@pankod/refine-antd";
import { useCan, useOne, useUpdate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IBaseProps, ILocations, IMetadata } from "interfaces";
import { IPosition, IPositionConfigSet, IPositionMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_CONFIGSET, DATAPROVIDER_LOOKUP, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, validateRGSIDs } from "scripts/site";

export declare type PositionActionProps = {
    position: IPosition;
    positionMetadata: IMetadata;
    onFormSubmission: () => void;
    refetch: () => void;
};
export const ManagePosition: React.FC<PositionActionProps> = ({ position, positionMetadata, onFormSubmission, refetch }) => {

    const [form] = Form.useForm<IPosition>();
    const { mutate } = useUpdate<IBaseProps>();
    const [submitButton, switchSubmitButton] = useState(false);
    const [isClosed, swicthIsClosed] = useState(false);
    const [isMapped, swicthIsMapped] = useState(false);
    const [isLCH, swicthIsLCH] = useState(false); // Lost, Cancelled or Hold
    const [isLCC, swicthIsLCC] = useState(false); // Lost, Closed or Cancelled
    const [fetchLocations, setFetchLocations] = useState(false);

    if (position.closureDate) {
        position.closureDate = dayjs(position.closureDate);
    }
    if (position.mappedDate) {
        position.mappedDate = dayjs(position.mappedDate);
    }
    if (position.tentativeClosureDate) {
        position.tentativeClosureDate = dayjs(position.tentativeClosureDate);
    }

    const { data: metadata, isLoading: metadataLoading } = useOne<IPositionMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.POSITION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    useEffect(() => {
        // Passed with Position, load only specific position
        if (position) {
            form.resetFields();
            setFetchLocations(true);
        }
    }, [position, form]);

    const { queryResult: locationsData } = useSelect<ILocations>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LOCATION,
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        filters: [
            {
                field: "countryId",
                operator: "eq",
                value: position.country
            }
        ],
        queryOptions: {
            enabled: fetchLocations
        },
        fetchSize: 100
    });

    const saveForm = () => {
        switchSubmitButton(true);
        form.validateFields().then((values) => {
            mutate({
                dataProviderName: DATAPROVIDER_UPDATE,
                resource: RESOURCE_PATH.POSITION,
                id: values.id,
                values: values,
                successNotification: { key: "notificationKey", message: "Successful", description: `Successfully updated the position`, type: "success" },
                errorNotification: { key: "notificationKey", message: "Error Occured", description: `Unable to update the Position`, type: "error" }
            },
                {
                    onSuccess: () => {
                        onFormSubmission();
                        refetch();
                        switchSubmitButton(false);
                    },
                    onError: () => {
                        onFormSubmission();
                        switchSubmitButton(false);
                    }
                }
            );
        }).catch((_errorInfo) => {
            notification.error({
                message: 'Incomplete Details',
                description:
                    'Please validate all the fields and save before submitting. Click save to preserve your responses.'
            });
            switchSubmitButton(false);
            return;
        });
    }

    // Form Submission Rules
    const setFormPreValidationRules = (stateAction: string) => {
        // Set validation flags as per action
        switch (stateAction) {
            case "mapped":
                swicthIsMapped(true);
                swicthIsClosed(false);
                swicthIsLCH(false);
                swicthIsLCC(false);
                break;

            case "hold":
                swicthIsMapped(false);
                swicthIsClosed(false);
                swicthIsLCH(true);
                swicthIsLCC(false);
                break;

            case "cancel":
            case "lost":
                swicthIsMapped(false);
                swicthIsClosed(false);
                swicthIsLCH(true);
                swicthIsLCC(true);
                break;

            case "closed":
                swicthIsMapped(false);
                swicthIsClosed(true);
                swicthIsLCH(false);
                swicthIsLCC(true);
                break;

            default:
                swicthIsClosed(false);
                swicthIsMapped(false);
                swicthIsLCH(false);
                swicthIsLCC(false);
                break;
        }
        form.setFieldValue("comments", getActions().find(a => a.value === stateAction)?.predefinedComments ?? null);
    }

    const metaConfig = metadata?.data.config;

    const actions: any[] = [];
    function getActions() {
        if (actions.length <= 0) {
            let currentState = positionMetadata.states.find(st => st.state === position.stateManager.state);

            if (currentState && currentState.nextStates.length > 0) {
                actions.push({ value: currentState.state, label: currentState.displayName })
                currentState.nextStates.forEach((st) => {
                    // Exclude requestClosure and rescoping action from position management
                    if (st.action !== "requestClosure" && st.action !== "rescope") {
                        actions.push(
                            {
                                value: st.action,
                                label: st.buttonText,
                                predefinedComments: st.predefinedComments,
                            }
                        );
                    }
                });
            }
        }

        return actions;
    };

    const { data: configSet, isLoading: configSetLoading } = useOne<IPositionConfigSet>({
        dataProviderName: DATAPROVIDER_CONFIGSET,
        resource: RESOURCE_PATH.POSITION,
        id: position?.id ?? "",
        queryOptions: {
            enabled: !!position?.id
        }
    });

    // Set the rules for the request based on the config set. If config set is not found, then use the metadata config as default.
    const configSetRules = configSet?.data?.values ?? metaConfig?.defaultConfigSet;

    const canEdit = useCan({
        resource: RESOURCE_PATH.POSITION,
        action: "edit",
        params: {
            dataProviderName: DATAPROVIDER_UPDATE,
            id: position?.id
        },
        queryOptions: {
            enabled: configSetRules && configSetRules?.editableStates?.includes(position?.stateManager?.state) && !!position?.id
        }
    });

    return (
        (metadataLoading || (position?.id && configSetLoading))
            ? <Result
                status="info"
                title="Checking permission !!!"
                subTitle="Kindly wait for few moments while we verify your permission and load this request for you."
            />
            : (canEdit.data?.can && configSetRules?.editableStates?.includes(position?.stateManager?.state))
                ? <Spin spinning={submitButton}>
                    <Form
                        layout="vertical"
                        initialValues={position}
                        autoComplete="off"
                        form={form}
                        onFinish={() => { form.validateFields() }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Form.Item
                                    label="Demand ID"
                                    name="id"
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Confidence"
                                    name="confidence"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        options={(["Confirmed", "Tentative"]).map(a => a)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Country"
                                    name="country"
                                    tooltip="Where is the project executed from?"
                                    rules={[
                                        {
                                            required: true,
                                            type: "string",
                                        }
                                    ]}
                                >
                                    <Input disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Emp ID of selected candidate"
                                    name="empIDSelectedCandidate"
                                    rules={[
                                        {
                                            required: isClosed,
                                            type: "number",
                                            whitespace: true
                                        },
                                    ]}
                                >
                                    <InputNumber min={0} step={1} precision={0} style={{ width: "100%" }} placeholder="Provide Emp ID of selected candidate" controls={false} />
                                </Form.Item>
                                <Form.Item
                                    label="Closure Channel"
                                    name="closureChannel"
                                    rules={[
                                        {
                                            required: isClosed || isMapped,
                                            type: "string",
                                            message: "Closure Channel is required for mapping or closing the position."
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select Closure Channel" allowClear>
                                        {metaConfig?.closureChannel.map((m, i) => (
                                            <Select.Option value={m} key={`cc-${i}`}>{m}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Work Location Constraints"
                                    name="locationConstraint"
                                    tooltip="Where are the associates expected to work from?"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        options={(["Remote", "MS Campus", "TCS Campus"]).map(a => a)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Mapped Date"
                                    name="mappedDate"
                                    tooltip={<>Provide position mapping date.<br />Click on the <Icons.CloseCircleFilled /> button to clear.</>}
                                    dependencies={['closureDate']}
                                    rules={[
                                        {
                                            required: isMapped || isClosed,
                                            whitespace: true,
                                            type: "date"
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_rule, value) {
                                                let closedDate = getFieldValue("closureDate");
                                                // Trigger validator only if both field exists
                                                if (value && closedDate && dayjs(value) > dayjs(closedDate)) {
                                                    return Promise.reject(new Error("Mapped date should be no later than closed date."));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }} picker="date"
                                        placeholder="YYYY-MM-DD"
                                        format={"YYYY-MM-DD"}
                                        disabledDate={(selDate) => selDate && selDate > dayjs().endOf('day')}
                                        allowClear={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="RGS ID"
                                    name="rgsId"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            type: "number",
                                            message: `RGS ID should be 7 digits long numeric value starting with 8 or 9`,
                                            validator: async (_, value) => {
                                                if (value && validateRGSIDs([value])) {
                                                    Promise.resolve();
                                                }
                                                else {
                                                    throw new Error("Provide Valid RGS ID");
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <InputNumber min={1} precision={0} placeholder="RGS ID" style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Billable/Non Billable"
                                    name="billable"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        options={(["Billable", "Non-Billable"]).map(a => a)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Location"
                                    name="location"
                                    tooltip="Where is the project executed from?"
                                    rules={[
                                        {
                                            required: true,
                                            type: "string",
                                            validator(_rule, value) {
                                                if ((isClosed || isMapped) && stringExtensions.stringEquals(value, `${position.country}-Any`)) {
                                                    return Promise.reject(new Error("Mapping/Closure of position can't have 'Country' as location."));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }
                                    ]}
                                >
                                    <Select placeholder="Select Location" showSearch>
                                        {locationsData?.data?.data.map((m, i) => (
                                            <Select.Option value={m.id} key={`lc-${i}`}>{m.name}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Name of selected candidate"
                                    name="nameSelectedCandidate"
                                    rules={[
                                        {
                                            required: isMapped || isClosed,
                                            whitespace: true,
                                            max: 100,
                                            message: "Name of selected candidate is required for mapping or closing the position."
                                        },
                                    ]}
                                >
                                    <Input placeholder="Provide name of selected candidate" />
                                </Form.Item>
                                <Form.Item
                                    label="Background Screening ID"
                                    name="backgroundScreeningID"
                                    rules={[
                                        {
                                            required: false,
                                            whitespace: true,
                                            max: 100
                                        },
                                    ]}
                                >
                                    <Input placeholder="Provide Background Screening ID for selected candidate" />
                                </Form.Item>
                                <Form.Item
                                    label="Customer Interview"
                                    name="customerInterview"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        options={(["Yes", "No"]).map(a => a)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Closure Date"
                                    name="closureDate"
                                    tooltip={<>Provide position closure date.<br />Click on the <Icons.CloseCircleFilled /> button to clear.</>}
                                    dependencies={['mappedDate']}
                                    rules={[
                                        {
                                            required: isLCC,
                                            whitespace: true,
                                            type: "date"
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_rule, value) {
                                                let mappedDate = getFieldValue("mappedDate");
                                                // Trigger validator only if both field exists
                                                if (value && mappedDate && dayjs(value) < dayjs(mappedDate)) {
                                                    return Promise.reject(new Error("Closure date should be later or same as mapped date."));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        }),
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }} picker="date"
                                        placeholder="YYYY-MM-DD"
                                        format={"YYYY-MM-DD"}
                                        disabledDate={(selDate) => selDate && selDate > dayjs().endOf('day')}
                                        allowClear={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Tentative Closure Date"
                                    name="tentativeClosureDate"
                                    tooltip={<>Provide tentative closure date.<br />Click on the <Icons.CloseCircleFilled /> button to clear.</>}
                                    rules={[
                                        {
                                            required: false,
                                            whitespace: true,
                                            type: "date"
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: "50%" }} picker="date"
                                        placeholder="YYYY-MM-DD"
                                        format={"YYYY-MM-DD"}
                                        allowClear={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Resourcing Constraints"
                                    name="resourceConstraint"
                                    rules={[
                                        {
                                            required: false,
                                            whitespace: true
                                        }
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        options={(["BA Only", "FTE Only", "Citizen Only", "Trainee Only", "None"]).map(a => a)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Notes"
                                    name="notes"
                                    tooltip="Use this for any remarks. Provide a reason when the position is On Hold, Cancelled or Lost"
                                    rules={[
                                        {
                                            required: isLCH,
                                            whitespace: true,
                                            max: 100,
                                            message: "Notes are required to mark a position as cancelled or Lost or On Hold"
                                        },
                                    ]}
                                >
                                    <Input.TextArea autoSize={{ minRows: 2, maxRows: 10 }}
                                        placeholder="Use this for any remarks. Provide a reason when the position is On Hold, Cancelled or Lost. Example: Can be used to add list of shortlisted candidates" />
                                </Form.Item>
                                <Form.Item
                                    label="Position Status"
                                    name="stateAction"
                                    initialValue={position.stateManager.state}
                                    tooltip="Select Status of the Position"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            max: 100
                                        },
                                    ]}
                                >
                                    <Radio.Group
                                        optionType="button"
                                        buttonStyle="solid"
                                        onChange={(ev) => { setFormPreValidationRules(ev.target.value) }}
                                        options={getActions()}
                                    />
                                </Form.Item>
                                <Form.Item name="comments" hidden={true}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        onClick={() => saveForm()}
                                        style={{ backgroundColor: "green" }}
                                        disabled={submitButton}
                                        icon={<Icons.SaveOutlined />}
                                    >
                                        Save
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
                : <Result
                    status="403"
                    title="Manage operation not allowed."
                    subTitle="Sorry, In current state, you don't have permission to edit this request. Kindly contact Resourcing Team for any changes needed."
                />
    );
};
