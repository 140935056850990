import { arrayExtensions, stringExtensions } from "lib-common";
import {
  Button, CreateButton, Drawer, EditButton, ExportButton,
  Icons, List, ShowButton, Space, Table, Tag, TextField, Typography,
  getDefaultSortOrder,
  useTable
} from "@pankod/refine-antd";
import {
  CrudFilters, HttpError, IResourceComponentsProps, useCan, useExport, useMany, useNavigation, useOne, useUpdate
} from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { DeActivateUsers } from "components/users/deactivateUsers";
import { ImportUsers } from "components/users/importUsers";
import { UserFilter } from "components/users/userFilter";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IBaseProps, ILookup, IUser, IUserFilterVariables, IUserMetadata } from "interfaces";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_EXPORT, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, parseTimeZone } from "scripts/site";

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();
  const { mutate } = useUpdate<IBaseProps>();
  const { tableProps, sorter, tableQueryResult, searchFormProps } = useTable<IUser, HttpError, IUserFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "lastAccessedAt",
        order: "desc"
      },
    ],
    permanentFilter: [
      // {
      //   field: "stateManager.state",
      //   operator: "eq",
      //   value: "active,inactive"
      // }
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, userType, location, country, role, state, contextualMaster, hobbies, technicalExpertiseAreas, functionalExpertiseAreas } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "userType",
        operator: "eq",
        value: userType
      });
      filters.push({
        field: "locationId",
        operator: "eq",
        value: location
      });
      filters.push({
        field: "countryId",
        operator: "eq",
        value: country
      });
      filters.push({
        field: "stateManager.state",
        operator: "eq",
        value: state
      });
      filters.push({
        field: "roleIds ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: role
      });
      filters.push({
        field: "employee.contextualMaster",
        operator: "eq",
        value: contextualMaster
      });
      filters.push({
        field: "hobbies ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: hobbies
      });
      filters.push({
        field: "technicalExpertiseAreas ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: technicalExpertiseAreas
      });
      filters.push({
        field: "functionalExpertiseAreas ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: functionalExpertiseAreas
      });
      return filters;
    }
  });

  const role_ids = arrayExtensions.arrayRemoveDuplicate(tableProps?.dataSource?.flatMap((item) => item?.roleIds) ?? []);
  const { data: roleData, isLoading } = useMany<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.ROLE,
    ids: role_ids,
    queryOptions: {
      enabled: role_ids.length > 0,
    },
  });

  function rowClick(record: IUser) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.USERS, record.id);
      },
    };
  }

  const handleActivation = (id: string, stateAction: string) => {
    mutate({
      dataProviderName: DATAPROVIDER_UPDATE,
      resource: RESOURCE_PATH.USERS,
      id: id,
      values: {
        stateAction: stateAction,
        comments: "User state updated by PMO/Admin"
      }
    },
      {
        onSuccess: () => {
          tableQueryResult.refetch();
        }
      }
    );
  }

  const { data: entityMetadata } = useOne<IUserMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.USERS,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const [filterOpen, switchFilterForm] = useState(false);
  const [importDrawer, setImportDrawer] = useState(false);
  const [reconcileDrawer, setReconcileDrawer] = useState(false);

  const { triggerExport, isLoading: exportLoading } = useExport<any>({
    dataProviderName: DATAPROVIDER_EXPORT,
    resourceName: RESOURCE_PATH.USERS,
    pageSize: metaConfig?.export.pageSize ?? 100,
    //maxItemCount: 150,
    filters: [
      {
        field: "userType",
        operator: "ne",
        value: "system"
      }
    ]
  });

  const canCreate = useCan({
    resource: RESOURCE_PATH.USERS,
    action: "create",
    params: {
      dataProviderName: DATAPROVIDER_CREATE,
      id: "1"
    }
  });

  return (
    <List
      headerProps={{
        title: "Helium Users",
        tags: <Tag color={"default"}>
          {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} User{tableQueryResult?.data?.total !== 1 && "s"}
        </Tag>,
        extra: <>
          <CreateButton></CreateButton>
          {metaConfig?.export.enabled && <ExportButton onClick={triggerExport} loading={exportLoading} />}
          {canCreate.data?.can && metaConfig?.importEnabled &&
            <Button key="imprtusr" icon={<Icons.ImportOutlined />} title="Import Employee" onClick={() => setImportDrawer(true)}>Import</Button>
          }
          {canCreate.data?.can && metaConfig?.reconcileEnabled &&
            <Button key="rcnslusr" icon={<Icons.ReconciliationOutlined />} title="Deactivate users" onClick={() => setReconcileDrawer(true)}>Reconcile</Button>
          }
          <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
        </>
      }}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value, record) => <>
            <div>
              <TextField value={value} />
            </div>
            {record?.linkedUserIds?.map((linked: string, i: number) => (<div key={i}>
              <Icons.LinkOutlined style={{ marginRight: 5 }} title="Linked User" />
              <ShowUserCard id={linked} />
            </div>))
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="id"
          key="id"
          onCell={rowClick}
          className="mouseHand"
          title="Email"
          render={(value, record) => <>
            <div>
              <Typography.Text copyable>{value}</Typography.Text>
            </div>
            {record?.linkedUserIds?.map((linked: string, i: number) => (<div key={i}>
              <Icons.LinkOutlined style={{ marginRight: 5 }} title="Linked User" />
              <Typography.Text copyable>{linked}</Typography.Text>
            </div>))
            }
          </>}
          sorter
        />

        <Table.Column
          dataIndex="userType"
          title="User Type"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />

        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="stateManager.state"
          title="Status"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />

        <Table.Column
          dataIndex="roleIds"
          title="Roles"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value: string[]) => <>
            {isLoading
              ? <TextField value="Loading..." />
              : arrayExtensions.sortByName(roleData?.data || [])
                .filter(r => value?.some(v => r.id === v))
                .map((r, i) => (
                  <div key={i} style={{ marginBottom: 5 }}>
                    <Tag color={r.ui?.color || "blue"}>{r.name || ""}</Tag>
                  </div>
                ))}
          </>}
        />

        <Table.Column
          dataIndex="locationId"
          title="Location"
          onCell={rowClick}
          className="mouseHand"
          defaultSortOrder={getDefaultSortOrder("locationId", sorter)}
          sorter
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="lastAccessedAt"
          key="lastAccessedAt"
          title="Last Seen"
          render={(value: string, userObj: IUser) => <>
            {value && displayRelativeDate(value)}
            {
              userObj.userTime &&
              <><br />
                <Typography.Text style={{ color: "GrayText" }}>{parseTimeZone(userObj.userTime)}</Typography.Text>
              </>
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("lastAccessedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex="updatedAt"
          key="updatedAt"
          title="Last Updated"
          render={displayRelativeDate}
          defaultSortOrder={getDefaultSortOrder("updatedAt", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* Hidden button to let AuthZ module check access for it */}
              <ShowButton hidden size="small" recordItemId={record.id} />
              <EditButton size="small" recordItemId={record.id} />
              <Button
                size="small"
                onClick={(ev) => { ev.preventDefault(); handleActivation(record.id, record.stateManager.state === "active" ? "inactive" : "active") }}>
                <Icons.UserSwitchOutlined />
                {record.stateManager.state === 'active' ? "De-activate" : "Activate"}
              </Button>
              {/* <DeleteButton size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Users - Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <UserFilter formProps={searchFormProps} mode="user" />
      </Drawer>
      <Drawer
        title={"Import Users"}
        placement="right"
        size="large"
        open={importDrawer}
        onClose={() => { setImportDrawer(false) }}
      >
        {entityMetadata && entityMetadata.data &&
          <ImportUsers {...entityMetadata.data} />
        }
      </Drawer>
      <Drawer
        title={"Reconcile Users"}
        placement="right"
        size="large"
        open={reconcileDrawer}
        onClose={() => { setReconcileDrawer(false) }}
      >
        {entityMetadata && entityMetadata.data &&
          <DeActivateUsers {...entityMetadata.data} />
        }
      </Drawer>
    </List>
  );
};
