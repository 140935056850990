import { DeleteButton, Divider, EditButton, Show, Table, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IEngagement, IEngagementSolution, IProgram, IRecordMetricValue } from "interfaces";
import { IScenario } from "interfaces/article";
import { arrayExtensions } from "lib-common";
import { DATAPROVIDER_DELETE, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, HELIUM_LOGO_PATH, RESOURCE_PATH, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, syncMetricValueList } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const EngagementSolutionShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult, showId } = useShow<IEngagementSolution>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IEngagementSolution;

  const { data: scenarioRecord } = useOne<IScenario>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.SCENARIO,
    id: record?.scenarioId ?? "",
    queryOptions: {
      enabled: !!record?.scenarioId
    },
  });

  const { data: engagmentRecord } = useOne<IEngagement>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ENGAGEMENT,
    id: record?.engagementId ?? "",
    queryOptions: {
      enabled: !!record?.engagementId
    },
  });

  const { data: programRecord } = useOne<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.PROGRAM,
    id: record?.programId ?? "",
    queryOptions: {
      enabled: !!record?.programId
    },
  });

  let recordKeyResults: IRecordMetricValue[] = [];
  if (record) {
    // Sync up the key results with the program key results
    if (!record?.keyResults) {
      record.keyResults = [];
    }
    recordKeyResults = syncMetricValueList(programRecord?.data?.keyResults ?? [], record.keyResults);
  }

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        title: (record?.name ?? ""),
        tags: <Tag color="blue">{record?.phase}</Tag>,
        backIcon: true,
        extra: <>
          <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
            height: 30,
            width: 150,
          }}></img>
          <CanAccess key={RESOURCE_PATH.ENGAGEMENTSOLUTION} resource={RESOURCE_PATH.ENGAGEMENTSOLUTION.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
            <EditButton></EditButton>
          </CanAccess>
          <CanAccess key={RESOURCE_PATH.ENGAGEMENTSOLUTION} resource={RESOURCE_PATH.ENGAGEMENTSOLUTION.toLowerCase()} action="delete" params={{ id: showId, dataProviderName: DATAPROVIDER_DELETE }}>
            <DeleteButton dataProviderName={DATAPROVIDER_DELETE}></DeleteButton>
          </CanAccess>
        </>
      }}
    >
      <Title level={5}>Scenario</Title>
      <Text>{scenarioRecord?.data?.name}</Text>

      <Title level={5}>Scenario Description</Title>
      <Text>{scenarioRecord?.data?.description}</Text>

      <Title level={5}>Engagement</Title>
      <Text>{engagmentRecord?.data?.name ?? ""}</Text>

      <Title level={5}>Impact</Title>
      <Text>{record?.impact}</Text>

      <Title level={5}>Phase</Title>
      <Text>{record?.phase}</Text>

      <Title level={5}>Status</Title>
      <Text>{record?.status}</Text>

      <Title level={5}>PoC Developed?</Title>
      <Text>{record?.pocDeveloped === true ? "Yes" : "No"}</Text>

      <Title level={5}>Client Contact</Title>
      <Text>{record?.clientContact ?? "---"}</Text>

      <Table<IRecordMetricValue> style={{ marginTop: 20, marginBottom: 20 }}
        dataSource={recordKeyResults} size="small"
        bordered={true}
        key={"krTable"} rowKey={"metricValue.id"}
        pagination={false}>
        <Table.Column
          dataIndex={["metric", "name"]}
          key="metric.name"
          title="Key Result"
        />
        <Table.Column
          dataIndex={["metric", "description"]}
          key="metric.description"
          title="Description"
        />
        <Table.Column
          dataIndex={["metric", "target"]}
          key="metric.target"
          title="Target"
        />
        <Table.Column
          dataIndex={["metricValue", "value"]}
          key="metricValue.value"
          title="Accomplished"
          render={(value, row, index) =>
            value + "%"
          }
        />
        <Table.Column
          dataIndex={["metricValue", "comments"]}
          key="metricValue.comments"
          title="Comments"
        />
      </Table>

      <Divider orientation="left">Context</Divider>
      {arrayExtensions.validateArray(record?.context ?? []) &&
        record?.context?.map((content, i) =>
          <div className="artcle" key={`art-${i}`}>
            {content.isImageAdded && content.alignment === "ImageAboveParagraph" &&
              <div className="img-cntnr">
                <img
                  key={`artimg-${i}`}
                  alt={record?.name}
                  src={record?.images?.find(img => img.uid === content.paraImage)?.url || HELIUM_LOGO_PATH}
                />
                {content.imageTitle && <Typography.Text style={{ color: "GrayText" }}>{content.imageTitle}</Typography.Text>}
              </div>
            }
            <div className="container" key={`para-${i}`} data-color-mode={getAppTheme()}>
              <MDEditor.Markdown source={content.paragraph} />
            </div>
            {content.isImageAdded && content.alignment === "ImageBelowParagraph" &&
              <div className="img-cntnr">
                <img
                  key={`artimg-${i}`}
                  alt={record?.name}
                  src={record?.images?.find(img => img.uid === content.paraImage)?.url || HELIUM_LOGO_PATH}
                />
                {content.imageTitle && <Typography.Text style={{ color: "GrayText" }}>{content.imageTitle}</Typography.Text>}
              </div>
            }
          </div>
        )
      }

      <Divider />
      <div>
        <ObjectMetadata {...record as IBaseProps}></ObjectMetadata>
      </div>
    </Show>
  );
};