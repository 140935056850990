import { arrayExtensions, stringExtensions } from "lib-common";
import { Button, DeleteButton, EditButton, Icons, Popconfirm, Show, Tabs, Tag, message } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCan, useCreate, useGetIdentity, useNavigation, useOne, useShow } from "@pankod/refine-core";
import { HistoryTab } from "components/common";
import { CandidateEvaluationList } from "components/resourcing/candidateEvaluationList";
import { PositionList } from "components/resourcing/positionList";
import { ShortlistedCandidateList } from "components/resourcing/shortlistedCandidateList";
import { RequestDetails } from "components/resourcing/showRequest";
import { SuggestedCandidateList } from "components/resourcing/suggestedCandidateList";
import { IUser } from "interfaces";
import { IPosition, IResourcingRequest, IResourcingRequestConfigSet, IResourcingRequestMetadata } from "interfaces/resourcing";
import { useEffect, useState } from "react";
import { DATAPROVIDER_CONFIGSET, DATAPROVIDER_CREATE, DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, appendToAppTitle, getGUID } from "scripts/site";

export const ResourcingRequestShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult, showId } = useShow<IResourcingRequest>({
    dataProviderName: DATAPROVIDER_READ,
  });

  const { data: userData } = useGetIdentity();
  const { edit, list } = useNavigation();
  const userObj = userData as IUser;
  const { data, isLoading } = queryResult;
  const record = data?.data as IResourcingRequest;
  const { mutate: cloneRequest } = useCreate<IResourcingRequest>();

  const [requestState, switchRequestState] = useState("draft");
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [cloningInProgress, setCloningInProgress] = useState(false);

  const { data: metadata } = useOne<IResourcingRequestMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.RESOURCINGREQUEST,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = metadata?.data.config;

  useEffect(() => {
    if (record?.stateManager) {
      switchRequestState(record?.stateManager.state);
    }
  }, [record?.stateManager, userObj])

  appendToAppTitle(record?.name);
  const handleCloning = () => {
    if (record) {
      setCloningInProgress(true);
      let excludeKeys = ["id", "stateManager", "requestID", "rgsIds", "positions", "requestReceivedDate", "updatedAt", "updatedBy", "createdAt", "createdBy", "_attachments", "_etag", "_partition", "_rid", "_self", "_ts", "replacementForEmployeeName", "replacementForEmployeeId", "replacementReason"];
      let baseRecord = record as any;
      let clonedRecord: any = { id: getGUID() };

      Object.keys(baseRecord).forEach(k => {
        if (!excludeKeys.includes(k)) {
          clonedRecord[k] = baseRecord[k];
        }
      });

      cloneRequest(
        {
          dataProviderName: DATAPROVIDER_CREATE,
          resource: RESOURCE_PATH.RESOURCINGREQUEST,
          values: clonedRecord,
        },
        {
          onError: () => {
            message.error(`Unable to copy resourcing request: ${record?.id}`);
            setCloningInProgress(false);
          },
          onSuccess: () => {
            setCloningInProgress(false);
            setOpenConfirmBox(false);
            message.info("Redirecting to cloned request!");
            setTimeout(() => {
              edit(RESOURCE_PATH.RESOURCINGREQUEST, clonedRecord?.id);
            }, 2000);
          }
        }
      )
    } else {
      // Unable to find the record details
      message.error(`Unable to fetch request details to be copied. Kindly try again.`);
      setOpenConfirmBox(false);
    }
  };

  let recordTabs: any[] = [];
  if (record) {
    // Add Main Tab
    recordTabs.push(
      {
        label: (<span><Icons.InfoOutlined />Request Details</span>),
        key: "details",
        children: <>
          {record && <RequestDetails {...record as IPosition} />}
        </>
      }
    );

    // Add Positions Tab, if positions exists
    if (record.positions && arrayExtensions.validateArray(record.positions)) {
      recordTabs.push({
        label: (<span><Icons.ContactsFilled />Positions</span>),
        key: "reqpstns",
        children: <PositionList requestId={record?.id} requestor={record?.requestor} />
      });
    }

    // Add Candidates Tab, if request is in published state
    if (record.stateManager.state === "published") {
      recordTabs.push({
        label: (<span><Icons.UsergroupAddOutlined />Suggested Profiles</span>),
        key: "reqcndts",
        children: <SuggestedCandidateList request={record} />
      });

      // Add Shortlisted Candidate Tab
      recordTabs.push({
        label: (<span><Icons.LinkOutlined />Shortlisted Profiles</span>),
        key: "reqmpcndts",
        children: <ShortlistedCandidateList requestId={record?.id} />
      });
    }

    // Evaluations Tab - Show from Publish state onwards
    if (!["draft", "submitted"].includes(record?.stateManager.state)) {
      recordTabs.push({
        label: (<span><Icons.CheckCircleOutlined />Evaluated Profiles</span>),
        key: "reqevls",
        children: <CandidateEvaluationList requestId={record?.id} />
      });
    }

    // Finally, add record history tab
    recordTabs.push({
      label: (<span><Icons.HistoryOutlined />History</span>),
      key: "stdorkflow",
      children: <HistoryTab stateManager={record?.stateManager} tabPosition='left' entityId={record?.id} entityName={RESOURCE_PATH.RESOURCINGREQUEST} />
    });
  }

  const { data: configSet } = useOne<IResourcingRequestConfigSet>({
    dataProviderName: DATAPROVIDER_CONFIGSET,
    resource: RESOURCE_PATH.RESOURCINGREQUEST,
    id: showId ?? "",
    queryOptions: {
      enabled: !!showId
    }
  });

  // Set the rules for the request based on the config set. If config set is not found, then use the metadata config as default.
  const requestRules = configSet?.data?.values ?? metaConfig?.defaultConfigSet;

  const canEdit = useCan({
    resource: RESOURCE_PATH.RESOURCINGREQUEST,
    action: "edit",
    params: {
      dataProviderName: DATAPROVIDER_UPDATE,
      id: record?.id
    },
    queryOptions: {
      enabled: (!!record?.id) && requestRules && requestRules?.editableStates?.includes(requestState)
    }
  });

  const canDelete = useCan({
    resource: RESOURCE_PATH.RESOURCINGREQUEST,
    action: "delete",
    params: {
      dataProviderName: DATAPROVIDER_DELETE,
      id: record?.id
    },
    queryOptions: {
      enabled: (!!record?.id) && requestRules && requestRules?.deletableStates?.includes(requestState)
    }
  });

  return (
    <div className="engagement">
      <Show
        isLoading={isLoading}
        headerProps={{
          title: record?.projectName,
          tags: record && <Tag color="blue" >{stringExtensions.capitalize(requestState)}</Tag>
        }}
        goBack=""
        headerButtons={
          <>
            {canEdit?.data?.can && requestRules?.editableStates?.includes(requestState) && <EditButton></EditButton>}

            {(record &&
              (record.requestor === userObj?.id || record.hiringManager === userObj?.id || record.hiringManagerDelegates?.some(hmd => hmd === userObj?.id))
              && requestState !== "draft") &&
              <Popconfirm
                title="This operation will create a copy of this Request. Kindly Confirm to proceed with cloning, Cancel otherwise"
                open={openConfirmBox}
                onConfirm={handleCloning}
                okButtonProps={{ loading: cloningInProgress }}
                okText="Confirm"
                onCancel={() => { setOpenConfirmBox(false) }}
                placement="bottomLeft"
              >
                <Button
                  onClick={() => { setOpenConfirmBox(true) }}
                  icon={<Icons.CopyOutlined />}>Clone</Button>
              </Popconfirm>
            }
            {canDelete.data?.can && requestRules?.deletableStates?.includes(requestState) &&
              <DeleteButton dataProviderName={DATAPROVIDER_DELETE} onSuccess={() => {
                list(RESOURCE_PATH.RESOURCINGREQUEST);
              }}></DeleteButton>
            }
          </>
        }
      >
        <Tabs style={{ marginBottom: 16 }}
          defaultActiveKey="details"
          items={recordTabs}
        />
      </Show >
    </div>
  );
};
