import { arrayExtensions } from "lib-common";
import { FilterDropdown, FilterDropdownProps, getDefaultSortOrder, Input, List, Table, Tag, TextField, Typography, useTable } from "@pankod/refine-antd";
import { CrudFilters, IResourceComponentsProps, LogicalFilter, useCan, useNavigation } from "@pankod/refine-core";
import { EngagementProgramCount } from "components/engagementprogram/engagementProgramCount";
import { ShowUserCard } from "components/profile/showUserCard";
import { ScenarioCount } from "components/scenario/scenarioCount";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { IBaseProps, IMetric, IProgram } from "interfaces";
import { DATAPROVIDER_READ, RESOURCE_PATH, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const ProgramList: React.FC<IResourceComponentsProps> = () => {
  const { show } = useNavigation();

  const initialFilters: CrudFilters = [{
    field: "name",
    operator: "contains",
    value: ""
  },
  {
    field: "objective",
    operator: "contains",
    value: ""
  }];

  const { tableProps, sorter, tableQueryResult } = useTable<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "_ts",
        order: "desc"
      },
    ],
    initialFilter: initialFilters,
  });
  const defaultOnChange = tableProps?.onChange ?? ((p, f, s, e) => { });

  function rowClick(record: IBaseProps) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.PROGRAM, record.id);
      },
    };
  }

  const canShowEnggProgram = useCan({
    resource: RESOURCE_PATH.ENGAGEMENTPROGRAM,
    action: "show"
  });

  return (
    <List
      headerProps={{
        title: "Programs",
        tags:
          <Tag color={"default"}>
            {tableQueryResult.isLoading ? "Loading" : tableQueryResult?.data?.total} Program{tableQueryResult?.data?.total !== 1 && "s"}
          </Tag>
      }}
    // headerButtons={() => (
    //   <>
    //     <CanAccess key={RESOURCE_PATH.PROGRAM} resource={RESOURCE_PATH.PROGRAM.toLowerCase()} action="create" params={{ dataProviderName: DATAPROVIDER_CREATE, id: "1" }}>
    //       <CreateButton></CreateButton>
    //     </CanAccess>
    //     <Button key="filter" icon={<Icons.SearchOutlined />} onClick={() => switchFilterForm(true)}>{metaConfig?.search?.textMessages?.buttonText || "Search & Filter"}</Button>
    //   </>
    // )}
    >
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }}>Total {total}</Typography.Title>)
        }}
        onChange={(p, f, s, e) => {
          initialFilters.forEach(fl => {
            // Set the value to blank string if filter value is undefined.
            // This will avoid filter getting removed from URL and getting reset to equal operator.
            let tempFl = fl as LogicalFilter;
            if (tempFl && !f[tempFl.field]) {
              f[tempFl.field] = [""];
            }
          });
          defaultOnChange(p, f, s, e);
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title=""
          width={100}
          render={() => <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI"
            style={{
              height: 20,
              width: 100,
            }}></img>}
          onCell={rowClick}
          className="mouseHand"
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Title"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"

          // Add text filter          
          filterDropdown={(props: FilterDropdownProps) => (
            <FilterDropdown {...props} >
              <Input />
            </FilterDropdown>
          )}
        />

        <Table.Column
          key="keyResults"
          dataIndex="keyResults"
          title="Key Results"
          onCell={rowClick}
          className="mouseHand"
          render={(value: any[]) =>
            value && value.map((r, i) => (
              <div key={i}>
                {r.name}
              </div>
            ))
          }
        />
        <Table.Column
          dataIndex="tasks"
          key="tasks"
          title="Tasks"
          onCell={rowClick}
          className="mouseHand"
          render={(value: IMetric[]) => <>
            {value && arrayExtensions.validateArray(value) &&
              <Typography.Text>{`${value.length}`}</Typography.Text>
            }
          </>} />
        <Table.Column
          dataIndex="journey"
          key="journey"
          title="Journey"
          onCell={rowClick}
          className="mouseHand"
          render={(value: IMetric[]) => {

            if (value && arrayExtensions.validateArray(value)) {
              const groupedJourney = arrayExtensions.groupBy(value ?? [], "group");
              return Object.keys(groupedJourney).map((key) => {
                return <div key={key}>
                  <Typography.Text>{`${key}`}</Typography.Text>
                </div>
              });
            }
            return <></>;
          }}
        />
        <Table.Column
          dataIndex="id"
          key="scenarios"
          title="Scenarios"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string) => <ScenarioCount programId={value} />}
        />
        {canShowEnggProgram.data?.can &&
          <Table.Column
            dataIndex="id"
            key="engagements"
            title="Engagements"
            onCell={rowClick}
            className="mouseHand"
            render={(value: string) => <EngagementProgramCount programId={value} />}
          />
        }

        <Table.Column
          key="createdBy"
          dataIndex="createdBy"
          title="Created By"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string) =>
            <ShowUserCard id={value} />
          }
        />
        <Table.Column
          dataIndex="_ts"
          key="_ts"
          title="Last Updated"
          render={(value) => displayRelativeDate(new Date(value * 1000))}
          defaultSortOrder={getDefaultSortOrder("_ts", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />
      </Table>
    </List>
  );
};