import { Button, Form, Icons, Input, Radio, Select, Spin, Table, TextField, message } from "@pankod/refine-antd";
import { useCreateMany, useList, useOne } from "@pankod/refine-core";
import { IBaseProps, ILookup } from "interfaces";
import { ICandidateEvaluation, IResourcingRequest, IResourcingRequestMetadata } from "interfaces/resourcing";
import { arrayExtensions } from "lib-common";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION, generateGroupFetchID, getGUID } from "scripts/site";

export declare type AssignCandidatesProps = {
    candidateIds: string;
    resourcingSPOCGroupName: string;
    onFormSubmission: () => void;
    refetch: () => void;
};

export const AssignCandidates: React.FC<AssignCandidatesProps> = ({ candidateIds, resourcingSPOCGroupName, onFormSubmission, refetch }) => {

    const { data: requestMetadata, isLoading: metadataLoading } = useOne<IResourcingRequestMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.RESOURCINGREQUEST,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { data: deliveryTeamResourcingSPOCs } = useOne<ILookup[]>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.GROUPS,
        id: generateGroupFetchID(resourcingSPOCGroupName),
        queryOptions: {
            enabled: resourcingSPOCGroupName.length > 0
        }
    });

    const requestMetaConfig = requestMetadata?.data.config;
    const [disableForm, setDisableForm] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);
    const [searchContent, setSearchContent] = useState<string>("");
    const [form] = Form.useForm<IBaseProps>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { mutate: createEvaluations } = useCreateMany<ICandidateEvaluation>();

    const { data: requestList, isLoading: requestsLocading } = useList<IResourcingRequest>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.RESOURCINGREQUEST,
        config: {
            filters: [{
                field: "stateManager.state",
                operator: "eq",
                value: "published"
            },
            {
                field: "q",
                operator: "contains",
                value: searchContent
            }
            ],
            sort: [{
                field: "updatedAt",
                order: "desc"
            }],
            hasPagination: false
        },
        queryOptions: {
            enabled: searchContent.length > 0
        }
    });

    const executeSearch = () => {
        let queryTerm = form.getFieldValue("q");
        if (queryTerm) {
            setSearchContent(queryTerm);
        }
    }

    const excuteAssignment = () => {
        let associationType = form.getFieldValue("associationType");
        let deliveryTeamSpoc = form.getFieldValue("deliveryTeamSpoc");
        let requestID = selectedRowKeys[0] as string;
        let evaluations: any[] = [];
        let candidates = candidateIds.split(",");
        setDisableForm(true);
        if (associationType === "Assign to a request") {
            if (requestID) {
                candidates.forEach((candidateId) => {
                    let evalId = getGUID();
                    // Create evaluation record for the candidate
                    let evaluation = {
                        id: evalId,
                        name: `Evaluation of ${candidateId.toString()} for ${requestID}`,
                        candidateId: candidateId.toString(),
                        requestId: requestID,
                        trCompleted: false
                    }
                    evaluations.push(evaluation);
                });
            }
        } else if (deliveryTeamSpoc) {
            candidates.forEach((candidateId) => {
                let evalId = getGUID();
                // Create evaluation record for the candidate
                let evaluation = {
                    id: evalId,
                    name: `Evaluation of ${candidateId.toString()}`,
                    candidateId: candidateId.toString(),
                    requestId: "",
                    assignedTo: deliveryTeamSpoc,
                    trCompleted: false
                }
                evaluations.push(evaluation);
            });
        }

        // Create Evaluations for Assignments
        if (evaluations.length > 0) {
            createEvaluations(
                {
                    resource: RESOURCE_PATH.CANDIDATEEVALUATION,
                    dataProviderName: DATAPROVIDER_CREATE,
                    values: evaluations,
                    errorNotification: false,
                    successNotification: { key: "notificationKey", message: "Successful", description: `Candidate assigment completed.`, type: "success" }
                },
                {
                    onError: () => {
                        message.error(`Unable to assign candidates ${candidateIds}. Evaluation creation failed. Kindly Retry.`);
                        setDisableForm(false);
                    },
                    onSuccess: () => {
                        // Execute Refetch
                        refetch();
                        onFormSubmission();
                        setDisableForm(false);
                        setSelectedRowKeys([]);
                    }
                }
            )
        } else {
            setDisableForm(false);
        }
    }
    return (
        <Spin spinning={metadataLoading || disableForm}>
            <Form
                layout="vertical"
                autoComplete="off"
                form={form}
                onFinish={() => { form.validateFields() }}
            >
                <Form.Item
                    label="How you want to associate candidate?"
                    name="associationType"
                    tooltip="Select candidate type for which you want to create profiles."
                    rules={[
                        {
                            required: true,
                            whitespace: true
                        }
                    ]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        name="associationType"
                        onChange={() => { setEnableSubmit(false); setSearchContent("") }}
                        options={["Assign to a request", "Assign to SPOC"].map(a => a)}
                    />
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.associationType !== currentValues.associationType}
                >
                    {({ getFieldValue }) =>
                        getFieldValue('associationType') === "Assign to a request" ? (
                            <>
                                <Form.Item
                                    label={"Search"}
                                    name="q"
                                    tooltip={"Search Resourcing Request to assign candidates. Recommended terms are Resourcing Request ID or Request ID"}
                                >
                                    <Input
                                        placeholder={requestMetaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Resourcing Request"}
                                        prefix={<Icons.SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item
                                    noStyle>
                                    <Button
                                        type="primary"
                                        onClick={() => executeSearch()}
                                    >
                                        <Icons.SaveOutlined />
                                        Search
                                    </Button>
                                </Form.Item>

                                {searchContent &&
                                    <Form.Item noStyle>
                                        <Table
                                            rowKey="id"
                                            style={{ marginTop: 15, marginBottom: 15 }}
                                            pagination={false}
                                            dataSource={requestList?.data || []}
                                            loading={requestsLocading}
                                            rowSelection={{
                                                type: "radio",
                                                selectedRowKeys,
                                                onChange: (selectedRowKeys: React.Key[]) => {
                                                    setSelectedRowKeys(selectedRowKeys);
                                                    setEnableSubmit(selectedRowKeys.length > 0);
                                                }
                                            }}
                                        >
                                            <Table.Column
                                                dataIndex="requestID"
                                                key="requestID"
                                                title="Request ID"
                                                render={(value) => <TextField value={value} />}
                                                className="mouseHand"
                                            />
                                            <Table.Column
                                                dataIndex="hiringManager"
                                                key="hiringManager"
                                                title="Hiring Manager"
                                                className="mouseHand"
                                            />
                                        </Table>
                                    </Form.Item>
                                }
                            </>
                        ) :
                            (
                                getFieldValue('associationType') === "Assign to SPOC" ?
                                    <>
                                        <Form.Item
                                            label="Delivery Team SPOC"
                                            name="deliveryTeamSpoc"
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    type: "email"
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select Delivery Team SPOC" onChange={() => setEnableSubmit(true)} showSearch>
                                                {arrayExtensions.sortByName(deliveryTeamResourcingSPOCs?.data || []).map((m, i) => (
                                                    <Select.Option value={m.id} key={`dt-spoc-${i}`}>{m.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </> : null
                            )
                    }
                </Form.Item>
                {enableSubmit &&
                    <Form.Item>
                        <Button
                            type="primary"
                            onClick={() => excuteAssignment()}
                            style={{ backgroundColor: "green" }}
                        >
                            <Icons.SaveOutlined />
                            Assign Candidates
                        </Button>
                    </Form.Item>
                }
            </Form>
        </Spin >
    );
}