import { stringExtensions } from "lib-common";
import { Button, Col, Form, FormProps, Icons, Input, Row, Select } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { IGroupsMetadata } from "interfaces";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const GroupsFilter: React.FC<{ formProps: FormProps }> = (props) => {
    const { isLoading, data: metadata } = useOne<IGroupsMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.GROUPS,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Row gutter={[10, 0]} align="bottom">
                    <Col xs={24} xl={24} md={12}>
                        <Form.Item
                            label={"Search"}
                            name="q"
                            tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Groups"}
                        >
                            <Input
                                placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Groups"}
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Group State"
                            name="state"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Group State"
                            >
                                {metadata?.data?.states.map((m, i) => (
                                    <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item
                            label="Group Type"
                            name="groupType"
                        >
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Group Type"
                            >
                                {["static", "dynamic"].map((m, i) => (
                                    <Select.Option value={m} key={i}>{stringExtensions.capitalize(m)}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} xl={24} md={8}>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                type="primary"
                            >
                                Apply Filter(s)
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                style={{ width: "100%" }}
                                htmlType="submit"
                                onClick={() => { props.formProps.form?.resetFields() }}
                            >
                                Clear Filter(s)
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    );
}