import { stringExtensions } from "lib-common";
import { Alert, Edit, Form, Input, SaveButton, Typography, useForm } from "@pankod/refine-antd";
import { IResourceComponentsProps, useOne } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { CancelButton } from "components/utils/cancelButton";
import { IAsset, IAssetMetadata } from "interfaces";
import { buildAttachmentUrl, DATAPROVIDER_UPDATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";
import { EditAssetConfig } from "./editAssetConfig";

export const AssetEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IAsset>({
    dataProviderName: DATAPROVIDER_UPDATE,
  });

  const { data: metadata } = useOne<IAssetMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ASSET,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaFields = metadata?.data.fields;
  const serialNumberUrl = buildAttachmentUrl(RESOURCE_PATH.METADATA, RESOURCE_PATH.ASSET, metaFields?.find(f => f.name === "serialNumber")?.attachment);

  const assetTypeObj = metadata?.data.config.assetTypes.find(a => a.name === queryResult?.data?.data.name);
  const record = queryResult?.data?.data;

  return (
    <Edit //saveButtonProps={saveButtonProps}
      headerProps={{
        extra: <></>,
        //className: "frm-wt-st-btn"
      }}
      isLoading={queryResult?.isFetching}
      footerButtons={() => (
        <>
          <SaveButton {...saveButtonProps} />
          <CancelButton />
        </>
      )}

    // actionButtons={
    //   <>
    //     <Space className="stt-frm-btn-frm" wrap align="end">
    //       <SaveButton {...saveButtonProps} />
    //       <CancelButton />
    //     </Space>
    //   </>
    // }
    >
      <Form {...formProps} autoComplete="off"
        layout="vertical"
      >
        {(record?.stateManager.assignedTo && record?.stateManager.assignedTo.length > 0) ?
          <Form.Item
            label="Allocated to"
            name="assignedTo"
          >
            <ShowUserCard id={record?.stateManager?.assignedTo[0]}></ShowUserCard>
          </Form.Item>
          :
          <></>
        }
        <Form.Item
          label="Asset Type"
          name="name"
          valuePropName="children"
        >
          <Typography.Text></Typography.Text>
        </Form.Item>

        <Form.Item
          label="Asset ID"
          name="id"
          valuePropName="children"
        >
          <Typography.Text></Typography.Text>
        </Form.Item>

        {/*Accounting for old data - Ethan 2/1*/}
        {(record?.countryId !== undefined) &&
          <Form.Item
            label="Asset Country"
            name="countryId"
            valuePropName="children"
          >
            <Typography.Text></Typography.Text>
          </Form.Item>
        }

        <Form.Item
          label="Asset Location"
          name="location"
          valuePropName="children"
        >
          <Typography.Text></Typography.Text>
        </Form.Item>

        <Form.Item
          tooltip={<>
            Launch windows Powershell and execute below command to identify the Computer Serial Number:
            <br />
            <strong>Gwmi win32_Bios | Select -Expand SerialNumber</strong>
            {serialNumberUrl &&
              <div>
                <a href={serialNumberUrl} rel="noreferrer" target={"_blank"}>View detailed steps</a>
              </div>
            }
          </>}
          label="Serial Number"
          name="serialNumber"
          rules={[
            {
              required: true,
              type: "string",
              whitespace: true,
              min: 5,
              max: 20,
            }
          ]}
        >
          <Input />
        </Form.Item>
        {assetTypeObj ? <EditAssetConfig {...assetTypeObj} fields={metaFields} isCRRequired={!stringExtensions.stringEquals(record?.osImage ?? "", "TCS")}></EditAssetConfig> : <></>}
        {
          record?.stateManager.workflowTitle ?
            <>
              <Alert
                message="Informational Note"
                description={record?.stateManager.workflowTitle}
                type="info"
                showIcon
              />
            </>
            : <></>
        }
      </Form>
    </Edit>
  );
};
