import { arrayExtensions } from "lib-common";
import { getDefaultSortOrder } from "@pankod/refine-antd";
import { CrudFilters, CrudSorting, useNavigation } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import { IEntityListColumns, IListTableColumn, IUserPreferences } from "interfaces";
import { TableFieldType, TableValueType } from "interfaces/enum";
import { DisplayColumnValue } from "./displayColumnValue";

export function GetListTableColumns<T extends { id: string }>(tableCols: IEntityListColumns | undefined, sorter: CrudSorting | undefined, entity: string): ColumnsType<any> {
    const { show } = useNavigation();
    function rowClick(record: T) {
        return {
            onClick: () => {
                show(entity, record.id);
            },
        };
    }

    function noOp() {
        return {
            onClick: () => {
            },
        };
    }
    const columns: ColumnsType<any> = [];
    if (tableCols) {
        tableCols.listColumns.filter(x => x.fieldType !== TableFieldType.Optional).forEach(col => {
            columns.push(
                {
                    key: col.key,
                    dataIndex: col.dataIndex.includes(".") ? col.dataIndex.split(".") : col.dataIndex,
                    title: col.title,
                    sortOrder: getDefaultSortOrder(col.dataIndex, sorter),
                    sorter: col.sort ?? true,
                    className: col.valueType !== TableValueType.ActionButton ? "mouseHand" : "",
                    onCell: col.valueType !== TableValueType.ActionButton ? rowClick : noOp,
                    render: (value, record) => DisplayColumnValue(value, col.valueType, entity, col.customRenderer, record, col.lookupEntityName),
                    ellipsis: true
                }
            );
        });
    }
    return columns;
}

// Extension Function to Merge the User Preferences with the standard list of Columns
export function mergePreferences(tblCols: IListTableColumn[], usrPreference: string[] | undefined, entity: string): IEntityListColumns {
    if (arrayExtensions.validateArray(tblCols) && usrPreference && arrayExtensions.validateArray(usrPreference)) {
        tblCols.filter(item => item.fieldType !== TableFieldType.Mandatory).forEach(item => {
            if (usrPreference.includes(item.key)) {
                item.fieldType = TableFieldType.Default;
            } else {
                item.fieldType = TableFieldType.Optional;
            }
        });
    }
    return { id: entity, name: entity, listColumns: tblCols };
}

// Extension function to formulate the User Preference Data for Saving
export function preferencesData(currentPreferences: IUserPreferences, entity: string, columnsData: string[] | undefined = undefined, filtersData: CrudFilters | undefined = undefined, sortOrder: CrudSorting | undefined = undefined, pageSize: number | undefined = undefined): IUserPreferences {
    let userPreferences: IUserPreferences = currentPreferences || { theme: "dark", entityListView: [] };

    // If preference node for entitites exists
    if (arrayExtensions.validateArray(userPreferences.entityListView)) {
        // Find the relevant entity inside the preferences
        let entry = userPreferences.entityListView?.find(x => x.id === entity);

        // If found, simply replace the value of fields
        if (entry) {
            entry.columns = columnsData ?? entry.columns;
            entry.filters = filtersData ?? entry.filters;
            entry.sortOrder = sortOrder ?? entry.sortOrder;
            entry.pageSize = pageSize ?? entry.pageSize;
            // If not found, add the node for specific entity
        } else {
            userPreferences.entityListView.push(
                {
                    id: entity,
                    columns: columnsData ?? [],
                    filters: filtersData ?? [],
                    sortOrder: sortOrder ?? [],
                    pageSize: pageSize ?? 10
                });
        }

    }
    // Complete node doesn't exists, insert node and update with the current specific entity save
    else {
        userPreferences.entityListView = [];
        userPreferences.entityListView.push(
            {
                id: entity,
                columns: columnsData ?? [],
                filters: filtersData ?? [],
                sortOrder: sortOrder ?? [],
                pageSize: pageSize ?? 10
            });
    }

    return userPreferences;
}