import { arrayExtensions, stringExtensions } from "lib-common";
import { AntdList, Button, Card, CreateButton, Drawer, Icons, List, Space, Tag, Typography, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useNavigation, useOne } from "@pankod/refine-core";
import { ArticleFilter } from "components/article/articleFilter";
import { RenderWorkflowModal } from "components/common";
import { RenderSocialButtons } from "components/common/socialButtons";
import { IState, IUser } from "interfaces";
import { IArticle, IArticleFilterVariables, IArticleMetadata } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, HELIUM_LOGO_PATH, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const ArticleList: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;
  const { show } = useNavigation();
  const [filterOpen, switchFilterForm] = useState(false);
  const [myArticles, setMyArticles] = useState(false);

  const listFilter: CrudFilters = [
    {
      field: "authorName ARRAY_CONTAINS_ANY",
      operator: "eq",
      value: myArticles ? user.id : ""
    },
    {
      field: "stateManager.state",
      operator: "eq",
      value: myArticles ? "" : "active,archived"
    }
  ];

  const { data: entityMetadata } = useOne<IArticleMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.ARTICLE,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const metaConfig = entityMetadata?.data?.config;
  const entityStates = entityMetadata?.data?.states as IState[] ?? [];

  const { listProps, queryResult, searchFormProps, filters } = useSimpleList<IArticle, HttpError, IArticleFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ARTICLE,
    permanentFilter: listFilter,
    pagination: { pageSize: 12 },
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, category, contentType, tag, authorName } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "category",
        operator: "eq",
        value: category
      });
      filters.push({
        field: "contentType",
        operator: "eq",
        value: contentType
      });
      filters.push({
        field: "tagList ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: tag
      });
      filters.push({
        field: "authorName ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: authorName
      });
      return filters;
    }
  });
  const totalRequests = queryResult?.data?.total ?? 0;
  let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

  const clearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  }

  return (
    <List
      headerProps={{
        title: (queryResult.isLoading ? "Loading" : totalRequests) + " Solution" + (totalRequests !== 1 ? "s" : ""),
        tags: <>
          <Button key="filter" type="default"
            icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
            style={{ paddingRight: (isFiltered ? 4 : 15) }}
            title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
            onClick={() => switchFilterForm(true)}>
            Search
            {isFiltered &&
              <>
                <Button
                  type="text"
                  danger
                  title={"Clear Filters"}
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    clearFilters();
                    e.stopPropagation();
                  }}
                  icon={<Icons.CloseOutlined />}
                >
                </Button>
              </>
            }
          </Button>
        </>
      }}
      headerButtons={() => (
        <Space>
          <CreateButton icon={<Icons.PlusOutlined />}>Add</CreateButton>
          <Button
            title={myArticles ? "Show All Solutions" : "Show My Solutions"}
            icon={myArticles ? <Icons.BarsOutlined /> : <Icons.SolutionOutlined />}
            onClick={() => setMyArticles(!myArticles)}
          >
            {myArticles ? "All Solutions" : "My Solutions"}
          </Button>
          {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
        </Space>
      )}
    >

      <AntdList
        grid={{
          gutter: 8,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 4,
        }}
        className="artcl-lst"
        {...listProps}
        renderItem={(item, indx) => {
          const allTags = [item?.category, item?.contentType, ...(item?.tagList ?? [])];
          return (<Card
            bodyStyle={{ height: "250px" }}
            key={`evnt-crd-${indx}`}
            actions={[
              //metaConfig?.socialElement?.displayStates?.includes(item?.stateManager?.state) &&
              <div style={{ paddingLeft: 10, paddingRight: 10, cursor: "default" }}>
                <RenderSocialButtons expanded={false} socialElementId={item?.socialElementId}
                  activeMode={metaConfig?.socialElement?.activeStates?.includes(item?.stateManager?.state) ?? true} />
              </div>
            ]}
            cover={<>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => show(RESOURCE_PATH.ARTICLE, item.id)}
                className="artcl-img"
                src={item.featuredImage ? item?.articleImages?.find(img => img.uid === item.featuredImage)?.url : HELIUM_LOGO_PATH}
                alt={item.name} />
              <Tag color="gold"
                style={{ position: "absolute", top: 5, left: 5, width: "auto" }}>
                {stringExtensions.capitalize(item?.stateManager?.state)}
              </Tag>
            </>}
          >
            <Card.Meta
              title={
                <>
                  <Typography.Title
                    level={5}
                    ellipsis={{
                      rows: 1,
                      tooltip: item.name,
                    }}
                  >
                    {item.name}
                  </Typography.Title>
                </>
              }
              description={
                <>
                  <Typography.Title
                    style={{
                      fontWeight: "unset",
                      fontSize: "14px",
                      color: "GrayText"
                    }}
                    level={5}
                    ellipsis={{
                      rows: 4,
                      tooltip: item.description,
                    }}
                  >
                    {item.description}
                  </Typography.Title>
                  <Typography.Title
                    style={{
                      fontWeight: "unset",
                      fontSize: "14px",
                      //color: "GrayText"
                    }}
                    level={5}
                    ellipsis={{
                      rows: 2,
                      tooltip: allTags.join(", "),
                    }}
                  >
                    {arrayExtensions.validateArray(allTags ?? [])
                      && allTags.map((e, i) => <Tag style={{ marginBottom: 7 }}
                        color={i === 0 ? "blue" : (i === 1 ? "green" : "default")}
                        key={`tag-${i}`}>{e}</Tag>)}
                  </Typography.Title>
                </>}
            />
          </Card>);
        }}
      />
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <ArticleFilter formProps={searchFormProps}
          onApplyFilter={() => switchFilterForm(false)}
        />
      </Drawer>
    </List >

  );
};