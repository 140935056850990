import { arrayExtensions } from "lib-common";
import { Col, DateField, DeleteButton, Divider, EditButton, Row, Show, Space, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useOne, useShow } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { LookupButton } from "components/utils/lookupButton";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, ILocations, IReservation } from "interfaces";
import { DATAPROVIDER_DELETE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION, extractTime, greyStyle } from "scripts/site";

export const ReservationShow: React.FC<IResourceComponentsProps> = () => {

    const { queryResult, showId } = useShow<IReservation>({
        dataProviderName: DATAPROVIDER_READ,
    });

    const { data, isLoading } = queryResult;
    const { Title, Text } = Typography;
    const record = data?.data as IReservation;

    const locationExists = record?.locationId !== undefined && record?.locationId !== "";
    const { data: locationData } = useOne<ILocations>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.LOCATION,
        id: record?.locationId,
        queryOptions: {
            enabled: locationExists,
            staleTime: STALE_DURATION
        }
    });

    return (
        <Show
            isLoading={isLoading}
            headerProps={{
                title: record?.name,
                extra: showId &&
                    <>
                        <CanAccess key={RESOURCE_PATH.RESERVATION + "-edit"} resource={RESOURCE_PATH.RESERVATION.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
                            <EditButton></EditButton>
                        </CanAccess>
                        <CanAccess key={RESOURCE_PATH.RESERVATION + "-delete"} resource={RESOURCE_PATH.RESERVATION.toLowerCase()} action="delete" params={{ id: showId, dataProviderName: DATAPROVIDER_DELETE }}>
                            <DeleteButton dataProviderName={DATAPROVIDER_DELETE}></DeleteButton>
                        </CanAccess>
                    </>
            }}
        >
            <Row gutter={[16, 16]}>
                <Col xl={12} xs={24}>
                    <Title level={5}>Title</Title>
                    <Text>{record?.name ?? "---"}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>Description</Title>
                    <Text>{record?.description ?? "---"}</Text>
                    <Title style={{ marginTop: 15 }} level={5}>Date</Title>
                    <Text>{record?.date && <DateField format="ll" value={record?.date} />}</Text>

                    <Title style={{ marginTop: 15 }} level={5}>Schedule</Title>
                    <Text>{extractTime(record?.begin)} - {extractTime(record?.end)}</Text>
                    {locationExists && locationData?.data?.timezone && <Tag color="cyan" style={{ marginLeft: 10 }}>{locationData?.data?.timezone}</Tag>}
                </Col>
                <Col xl={12} lg={24} xs={24}>
                    <Title level={5}>Location</Title>
                    <LookupButton id={record?.countryId || ""} resource={RESOURCE_PATH.COUNTRY} noClick></LookupButton>
                    <Text style={greyStyle}>{" / "}</Text>
                    <LookupButton id={record?.locationId || ""} resource={RESOURCE_PATH.LOCATION} noClick></LookupButton>
                    <Text style={greyStyle}>{" / "}</Text>
                    <LookupButton id={record?.buildingId || ""} resource={RESOURCE_PATH.BUILDING} noClick></LookupButton>
                    <Text style={greyStyle}> {" / "}</Text>
                    <LookupButton id={record?.zoneId || ""} resource={RESOURCE_PATH.ZONE} noClick></LookupButton>
                    <Text style={greyStyle}> {" / "}</Text>
                    <LookupButton id={record?.roomId || ""} resource={RESOURCE_PATH.ROOM}></LookupButton>
                    <Title style={{ marginTop: 15 }} level={5}>Organizer</Title>
                    <ShowUserCard id={record?.organizer} ></ShowUserCard>
                    <Title style={{ marginTop: 15 }} level={5}>Attendees</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.attendees ?? []) ? record?.attendees?.map((e, i) => <ShowUserCard key={`att-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>
                </Col>
            </Row>

            <Divider></Divider>
            <div style={{ marginTop: 15 }}>
                <ObjectMetadata {...record as IBaseProps} ></ObjectMetadata>
            </div>
        </Show>
    )
}