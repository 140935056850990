import { DeleteButton, Divider, EditButton, Icons, MarkdownField, Show, Space, Table, Tabs, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useNavigation, useOne, useShow } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { ShowUserCard } from "components/profile/showUserCard";
import { ObjectMetadata } from "components/utils/objectMetadata";
import { IBaseProps, IEngagement, IEngagementProgram, IProgram, IRecordMetricValue } from "interfaces";
import { arrayExtensions } from "lib-common";
import { DATAPROVIDER_DELETE, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, buildAttachmentUrl, greyStyle, syncMetricValueList, useAttachment } from "scripts/site";
import { getAppTheme } from "scripts/theme";
import { EnggSolutionList } from "../engagementsolution/solutionList";

export const EngagementProgramShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult, showId } = useShow<IEngagementProgram>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IEngagementProgram;

  const { data: engagmentRecord } = useOne<IEngagement>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.ENGAGEMENT,
    id: record?.engagementId ?? "",
    queryOptions: {
      enabled: !!record?.engagementId
    },
  });

  const { data: programRecord } = useOne<IProgram>({
    dataProviderName: DATAPROVIDER_READ,
    resource: RESOURCE_PATH.PROGRAM,
    id: record?.programId ?? "",
    queryOptions: {
      enabled: !!record?.programId
    },
  });

  let recordKeyResults: IRecordMetricValue[] = [];
  let recordTasks: IRecordMetricValue[] = [];
  let recordJourney: IRecordMetricValue[] = [];

  if (record) {
    // Sync up the key results with the program key results
    if (!record?.keyResults) {
      record.keyResults = [];
    }
    recordKeyResults = syncMetricValueList(programRecord?.data?.keyResults ?? [], record.keyResults);
    // Sync up the tasks with the program tasks
    if (!record?.tasks) {
      record.tasks = [];
    }
    recordTasks = syncMetricValueList(programRecord?.data?.tasks ?? [], record.tasks);

    // Sync up the tasks with the program jounrey
    if (!record?.journey) {
      record.journey = [];
    }
    recordJourney = syncMetricValueList(programRecord?.data?.journey ?? [], record.journey);
  }

  const taskGroupNames = arrayExtensions.arrayRemoveDuplicate((programRecord?.data?.tasks ?? []).flatMap(t => t.group));
  const journeyGroupNames = arrayExtensions.arrayRemoveDuplicate((programRecord?.data?.journey ?? []).flatMap(t => t.group));

  function renderMetricValueGroup(groups: string[], recordMetricValue: IRecordMetricValue[]) {
    return (<>
      {arrayExtensions.validateArray(recordMetricValue ?? [])
        ? groups.map((grp, ind) =>
          <div key={ind} style={{ marginBottom: 25 }}>
            <Title level={5}>{grp}</Title>
            {(arrayExtensions.sortBy("order", recordMetricValue?.filter(rt => rt.metric.group === grp) ?? []) as IRecordMetricValue[])
              .map((rt, i) => {
                return (<div key={i} style={{ marginBottom: 10 }}>
                  <Space>
                    <Tag key={"tag"}
                      style={{ width: 80, textAlign: "center" }}
                      color={rt.metricValue.value ? "green" : "red"}>{rt.metricValue.value ? "Completed" : "Pending"}</Tag>
                    <div key={"text"}>
                      <Text>{`${rt.metric.name}`}</Text>
                      {rt.metric.description && <>
                        <br />
                        <Text style={greyStyle}>{rt.metric.description}</Text>
                      </>}
                    </div>
                  </Space>
                </div>);
              })}
          </div>)
        : "None"}
    </>);
  }

  const { goBack } = useNavigation();

  const mdUrl = buildAttachmentUrl(RESOURCE_PATH.PROGRAM, record?.programId, programRecord?.data?.mdPath);

  const { response: mdData } = useAttachment(mdUrl);

  return (
    <div className="engagement">
      <Show
        isLoading={isLoading}
        canDelete={true}
        headerProps={{
          title: "Program for " + (engagmentRecord?.data?.name ?? ""),
          backIcon: true,
          extra: <>
            <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
              height: 30,
              width: 150,
            }}></img>
            <CanAccess key={RESOURCE_PATH.ENGAGEMENTPROGRAM} resource={RESOURCE_PATH.ENGAGEMENTPROGRAM.toLowerCase()} action="edit" params={{ id: showId, dataProviderName: DATAPROVIDER_UPDATE }}>
              <EditButton></EditButton>
            </CanAccess>
            <CanAccess key={RESOURCE_PATH.ENGAGEMENTPROGRAM} resource={RESOURCE_PATH.ENGAGEMENTPROGRAM.toLowerCase()} action="delete" params={{ id: showId, dataProviderName: DATAPROVIDER_DELETE }}>
              <DeleteButton onSuccess={goBack} dataProviderName={DATAPROVIDER_DELETE} ></DeleteButton>
            </CanAccess>
          </>
        }}
      >
        <Tabs defaultActiveKey={"program"} style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }}
          items={[
            {
              label: <span><Icons.AimOutlined />OKRs</span>,
              key: "program",
              style: { margin: 8 },
              children: <>
                <Title level={4}>Objective</Title>
                <div className="container" data-color-mode={getAppTheme()}>
                  <MDEditor.Markdown source={programRecord?.data?.objective} />
                </div>
                <Table<IRecordMetricValue> style={{ marginTop: 20 }}
                  dataSource={recordKeyResults} size="small"
                  bordered={true}
                  key={"krTable"} rowKey={"metricValue.id"}
                  pagination={false}>
                  <Table.Column
                    dataIndex={["metric", "name"]}
                    key="metric.name"
                    title="Key Result"
                  />
                  <Table.Column
                    dataIndex={["metric", "description"]}
                    key="metric.description"
                    title="Description"
                  />
                  <Table.Column
                    dataIndex={["metric", "target"]}
                    key="metric.target"
                    title="Target"
                  />
                  <Table.Column
                    dataIndex={["metricValue", "value"]}
                    key="metricValue.value"
                    title="Accomplished"
                    render={(value, row, index) =>
                      value + "%"
                    }
                  />
                  <Table.Column
                    dataIndex={["metricValue", "comments"]}
                    key="metricValue.comments"
                    title="Comments"
                  />
                </Table>
                <Divider />
                <Title level={5}>Program SPOC</Title>
                <ShowUserCard id={record?.programSpoc} />

                <Title style={{ marginTop: 10 }} level={5}>Program Champions</Title>
                <Space wrap split={<Divider type="vertical" />} >
                  {arrayExtensions.validateArray(record?.programChampions ?? []) ? record?.programChampions?.map((e, i) => <ShowUserCard key={`pc-${i}`} id={e}></ShowUserCard>) : "None"}
                </Space>
                <Divider />
                <div>
                  <ObjectMetadata {...record as IBaseProps}></ObjectMetadata>
                </div>
              </>
            },
            {
              label: <span><Icons.OrderedListOutlined />Tasks</span>,
              key: "tasks",
              style: { margin: 8 },
              children: renderMetricValueGroup(taskGroupNames, recordTasks)
            },
            {
              label: <span><Icons.NodeIndexOutlined />Journey</span>,
              key: "journey",
              style: { margin: 8 },
              children: renderMetricValueGroup(journeyGroupNames, recordJourney),
            },
            {
              label: <span><Icons.BlockOutlined />Scenarios</span>,
              key: "scenarios",
              style: { margin: 8 },
              children: <EnggSolutionList programId={record?.programId} engagementId={record?.engagementId} />
            },
            {
              label: <span><Icons.QuestionOutlined />Help</span>,
              key: "help",
              style: { margin: 8 },
              children: <div className={"markdown"}><MarkdownField value={mdData || "# Loading..."} ></MarkdownField></div>

            }
          ]}
        >
        </Tabs>
      </Show>
    </div>
  );
};