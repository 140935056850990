import { arrayExtensions, stringExtensions } from "lib-common";
import { Table, Tag } from "@pankod/refine-antd";
import { apiToWebAction, useAuthZ } from "scripts/site";

export const DisplayAuthZ: React.FC = () => {
    const { authZ, isLoading } = useAuthZ();

    return (
        <Table
            loading={isLoading}
            dataSource={arrayExtensions.sortBy("name", authZ ?? [])}
            pagination={{
                hideOnSinglePage: true,
                pageSize: 50,
                //showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
            }}
            rowKey="id"
            scroll={{ x: true }}
        >

            <Table.Column
                dataIndex="name"
                key="name"
                title="Entity"

            />

            <Table.Column
                dataIndex="allowedPerms"
                key="allowedPerms"
                title="Allow"
                render={(value: any[]) => (
                    value.map((ac, i) => {
                        const scopeAll = ac.scope === "All";
                        return (<Tag key={i} color={scopeAll ? "blue" : "red"}>
                            {stringExtensions.capitalize(apiToWebAction(ac.action)) + (scopeAll ? "" : " (" + ac.scope + ")")}
                        </Tag>);
                    })
                )}
            />
            <Table.Column
                dataIndex="denyPerms"
                key="denyPerms"
                title="Deny"
                render={(value: any[]) => (
                    value.map((ac, i) => {
                        const scopeAll = ac.scope === "All";
                        return (<Tag key={i} color={scopeAll ? "blue" : "red"}>
                            {stringExtensions.capitalize(apiToWebAction(ac.action)) + (scopeAll ? "" : " (" + ac.scope + ")")}
                        </Tag>);
                    })
                )}
            />
        </Table >
    );
};