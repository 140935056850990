import {  arrayExtensions, stringExtensions } from "lib-common";
import { Divider, EditButton, Show, Space, Tag, Typography } from "@pankod/refine-antd";
import { CanAccess, IResourceComponentsProps, useCan, useOne, useShow } from "@pankod/refine-core";
import { RenderTimelineModal, StateButtons } from "components/common";
import { RenderSocialButtons } from "components/common/socialButtons";
import { ShowUserCard } from "components/profile/showUserCard";
import { LookupButton } from "components/utils/lookupButton";
import dayjs from "dayjs";
import { IScenario, IScenarioMetadata } from "interfaces/article";
import { EnggSolutionList } from "pages/delivery/engagementsolution/solutionList";
import { useEffect, useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, appendToAppTitle } from "scripts/site";
import { IBaseProps } from "interfaces";

export const ScenarioShow: React.FC<IResourceComponentsProps> = () => {

  const { queryResult } = useShow<IScenario>({
    dataProviderName: DATAPROVIDER_READ
  });

  const { data: metadata } = useOne<IScenarioMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.SCENARIO
  });
  const metaConfig = metadata?.data?.config;

  const { data, isLoading } = queryResult;
  const { Title, Text } = Typography;
  const record = data?.data as IScenario;
  appendToAppTitle(record?.name);
  const [socialElementId, setSocialElementId] = useState(record?.socialElementId);

  useEffect(() => {
    setSocialElementId(record?.socialElementId);
  }, [record])

  const canShowEnggSolution = useCan({
    resource: RESOURCE_PATH.ENGAGEMENTSOLUTION,
    action: "show"
  });

  return (
    <Show
      isLoading={isLoading}
      headerProps={{
        title: record?.name,
        backIcon: true,
        tags: <Tag color="gold">{stringExtensions.capitalize(record?.stateManager.state) ?? "Loading"}</Tag>,
        extra: <>
          {record &&
            <CanAccess key={RESOURCE_PATH.SCENARIO} resource={RESOURCE_PATH.SCENARIO.toLowerCase()} action="edit" params={{ dataProviderName: DATAPROVIDER_UPDATE, id: record.id }}>
              <Space>
                {metaConfig?.editableStates?.includes(record.stateManager.state) && <EditButton style={{ marginRight: 8 }} ></EditButton>}
                <StateButtons {...record as IBaseProps}
                  resourceToAct={RESOURCE_PATH.SCENARIO}
                  resourceToActId={record?.id || "0"}
                  resourceToNavigate={RESOURCE_PATH.WORKLIST}
                />
                {record && <RenderTimelineModal stateManager={record.stateManager} entityId={record?.id} entityName={RESOURCE_PATH.SCENARIO} />}
              </Space>
            </CanAccess>
          }
        </>
      }}
    >
      {/* <Title level={3}>{record?.name}</Title> */}
      {record?.authorName &&
        <>
          <Text style={{ color: "GrayText" }}>By: </Text>
          <Space wrap split={<Divider type="vertical" />} >
            {arrayExtensions.validateArray(record?.authorName ?? []) ? record?.authorName?.map((e, i) => <ShowUserCard key={`author-${i}`} id={e}></ShowUserCard>) : "None"}
          </Space>
        </>
      }

      {record?.programId &&
        <div>
          <Text style={{ color: "GrayText" }}>Program: </Text>
          <LookupButton id={record?.programId || ""} resource={RESOURCE_PATH.PROGRAM}></LookupButton>
        </div>
      }
      {record?.publishedDate &&
        <div>
          <Text style={{ color: "GrayText" }}>Published on: </Text>
          <Text style={{ color: "GrayText" }}>{dayjs(record.publishedDate).format('LLLL')}</Text>
        </div>
      }
      {<div style={{ marginTop: 10 }}>
        <RenderSocialButtons socialElementId={socialElementId} activeMode={metaConfig?.socialElement?.activeStates?.includes(record?.stateManager?.state) ?? true} />
      </div>}

      <Divider></Divider>
      <Title level={5}>Description</Title>
      <Text>{record?.description}</Text>

      <Title style={{ marginTop: 15 }} level={5}>Service Lines</Title>
      <Space wrap split={<Divider type="vertical" />} >
        {arrayExtensions.validateArray(record?.serviceLineIds ?? []) ? record?.serviceLineIds?.map((e, i) => <LookupButton resource={RESOURCE_PATH.SERVICELINE} noClick key={`other-${i}`} id={e}></LookupButton>) : "None"}
      </Space>

      <Title style={{ marginTop: 15 }} level={5}>Fulfilled via Technology</Title>
      <Space wrap split={<Divider type="vertical" />} >
        {arrayExtensions.validateArray(record?.technology ?? []) ? record?.technology?.map((e, i) => <Text>{e}</Text>) : "None"}
      </Space>

      <Title style={{ marginTop: 15 }} level={5}>Value Dimensions</Title>
      <Space wrap split={<Divider type="vertical" />} >
        {arrayExtensions.validateArray(record?.valueDimensions ?? []) ? record?.valueDimensions?.map((e, i) => <Tag color="blue">{e}</Tag>) : "None"}
      </Space>

      {canShowEnggSolution.data?.can &&
        <>
          <Divider></Divider>
          <EnggSolutionList programId={record?.programId} scenarioId={record?.id} />
        </>}
    </Show>
  );
};