import { ILookup, IOrderable } from "interfaces";

class arrayExtensionsClass {
    public validateArray<T>(arr: Array<T>): boolean {
        return arr && Array.isArray(arr) && arr.length > 0;
    }

    public arrayRemoveDuplicate(inputArr: string[]): string[] {
        var result: string[] = [];
        new Set(inputArr).forEach(v => result.push(v));
        return result;
    }

    public sortByOrder(arr: IOrderable[]): IOrderable[] {
        if (this.validateArray(arr)) {
            arr = arr.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
        }
        return arr;
    }

    public sortByName(arr: ILookup[]): ILookup[] {
        if (this.validateArray(arr)) {
            return arr.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });
        }
        return [];
    }

    public sortBy(field: string, arr: any[]): any[] {
        if (this.validateArray(arr)) {
            return arr.sort((a, b) => {
                if (a[field] < b[field]) {
                    return -1;
                }
                if (a[field] > b[field]) {
                    return 1;
                }
                return 0;
            });
        }
        return [];
    }

    public groupBy(arr: any[], key: string) {
        return arr.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    public sortArrayByOrder(arr: any[]): any[] {
        if (arrayExtensions.validateArray(arr)) {
            arr = arr.sort((a, b) => {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });
        }
        return arr;
    }
}
export const arrayExtensions = new arrayExtensionsClass();