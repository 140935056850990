import { Button, Form, FormProps, Icons, Input, Select, Space, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { ILookup } from "interfaces";
import { IArticleMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const ArticleFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter?: () => void,
}> = (props) => {
    const { isLoading, data: metadata } = useOne<IArticleMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.ARTICLE,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        fetchSize: 50
    });
    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="category"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Category"
                    >
                        {metaConfig?.category.sort().map((m, i) => (
                            <Select.Option value={m} key={i}>{m}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Content Type"
                    name="contentType"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Content Type"
                    >
                        {metaConfig?.contentType.sort().map((m, i) => (
                            <Select.Option value={m} key={i}>{m}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Authors"
                    name="authorName"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        //mode="multiple"
                        placeholder="Authors"
                        {...userProps}
                    />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button
                            //style={{ width: "100%" }}
                            htmlType="submit"
                            type="primary"
                            icon={<Icons.FilterOutlined />}
                            onClick={() => {
                                props.onApplyFilter && props.onApplyFilter();
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            //style={{ width: "100%" }}
                            icon={<Icons.ClearOutlined />}
                            htmlType="submit"
                            onClick={() => { props.formProps.form?.resetFields() }}
                        >
                            Clear
                        </Button>
                    </Space>
                </Form.Item>

            </Form>
    );
}