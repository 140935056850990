import { arrayExtensions } from "lib-common";
import { Button, Card, Create, Form, Icons, Input, SaveButton, Select, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useOne } from "@pankod/refine-core";
import MDEditor from "@uiw/react-md-editor";
import { CancelButton } from "components/utils/cancelButton";
import { ILookup, IUser } from "interfaces";
import { IPrompt, IPromptContent, IPromptMetadata } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const PromptCreate: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;

  const { form, formProps, saveButtonProps } = useForm<IPrompt>({
    dataProviderName: DATAPROVIDER_CREATE
  });

  const { data: metadata } = useOne<IPromptMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.PROMPT,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });

  const metaConfig = metadata?.data.config;

  const { selectProps: usersList } = useSelect<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PEOPLE,
    optionLabel: "nameEmail",
    optionValue: "id",
    filters: [{
      field: "stateManager.state",
      operator: "eq",
      value: "active"
    }],
    sort: [{
      field: "lastAccessedAt",
      order: "desc"
    }]
  });
  const [isSavingData, setIsSavingData] = useState(false);

  return (
    <Create
      title="Author a Prompt"
      isLoading={isSavingData}
      footerButtons={<>
        <SaveButton {...saveButtonProps} onClick={() => {
          setIsSavingData(true);

          // remove empty fields before saving
          form?.validateFields().then(async () => {
            // fetch all applicableTo from all prompts
            const allApplicableTo = ((form?.getFieldValue(["body"]) ?? []) as IPromptContent[]).flatMap(b => b.applicableTo);
            // remove duplicates and set the value to form field applicableTo
            form?.setFieldValue(["applicableTo"], arrayExtensions.arrayRemoveDuplicate(allApplicableTo));
            form.submit();
          }).then(() => { setIsSavingData(false); }).catch(() => { setIsSavingData(false); });

        }} />
        <CancelButton />
      </>}

    >
      <Form {...formProps} layout="vertical" autoComplete="off"
        initialValues={{
          body: [{ prompt: "" }],
          authorName: user?.id ? [user?.id] : []
        }}
      >
        <Form.Item
          label="Title"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 5,
              max: 100
            },
          ]}
        >
          <Input placeholder="Title for the prompt" />
        </Form.Item>
        <Form.Item
          label="Intent"
          name="description"
          rules={[
            {
              required: true,
              whitespace: true,
              min: 15,
              max: 200
            },
          ]}
        >
          <Input.TextArea rows={2} placeholder="Short abstract outlining intent of the prompt" />
        </Form.Item>

        <Form.List
          name="body"
          rules={[
            {
              validator: async (_, body) => {
                if (!body || body.length < 1) {
                  return Promise.reject(new Error('Minimum 1 prompt is required'));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Card
                  style={{ marginBottom: 10 }}
                  size="small"
                  title={`Prompt ${index + 1}`}
                  key={field.key}
                  extra={
                    <Button
                      type="text"
                      danger
                      title={"Remove Prompt"}
                      size="small"
                      //style={{ marginLeft: 5 }}
                      onClick={(e) => {
                        remove(field.name);
                      }}
                      icon={<Icons.CloseOutlined />}
                    >
                    </Button>
                  }
                >
                  <Form.Item
                    label="Applicable Platforms"
                    name={[index, "applicableTo"]}
                    rules={[
                      {
                        required: true,
                        type: "array"
                      },
                    ]}
                  >
                    <Select placeholder="Search" mode="multiple"
                      options={((metaConfig?.copilots || []).concat(metaConfig?.models || [])).map((m, i) => ({ value: m, label: m }))}
                      allowClear>
                      {/* {metaConfig?.copilots.sort().map((m, i) => (
                        <Select.Option value={m} key={`rr-${i}`}>{m}</Select.Option>
                      ))} */}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Prompt"
                    name={[index, "prompt"]}
                    tooltip="Provide the content for prompt"
                    rules={[
                      {
                        required: true
                      },
                    ]}
                  >
                    <MDEditor height={250} data-color-mode={getAppTheme()} />
                  </Form.Item>

                  <Form.Item
                    label="Sample Output"
                    name={[index, "sampleOutput"]}
                    tooltip="Provide the sample output which may be generated after using prompt"
                    rules={[
                      {
                        required: false
                      },
                    ]}
                  >
                    <MDEditor height={250} data-color-mode={getAppTheme()} />
                  </Form.Item>
                </Card>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  style={{ width: "100%" }}
                  onClick={() => add()}
                  icon={<Icons.PlusOutlined />}
                >
                  Add Prompt
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* <Divider orientation="left">Metadata</Divider> */}
        <Form.Item
          label="Applicable Platforms"
          name="applicableTo"
          hidden={true}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Author(s)"
          name="authorName"
          rules={[
            {
              required: true,
              whitespace: true,
              type: "array",
              min: 1,
              max: 3,
              message: "Minimum 1 and maximum of 3 authors"
            },
          ]}
        >
          <Select placeholder="Search Name or Email" mode="multiple" {...usersList} allowClear />
        </Form.Item>
      </Form>
    </Create >
  );
};