import { Button, Col, Divider, Row, Space, Typography } from "@pankod/refine-antd";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { rowGutter, threeColumnLayout } from "scripts/layout";

import { ICountry, ILocations } from "interfaces";
import { ShowUserCard } from "components/profile/showUserCard";
import { RESOURCE_PATH } from "scripts/site";
import { useNavigation } from "@pankod/refine-core";
import { arrayExtensions } from "lib-common";

export const LocationDetails: React.FC<ICountry | ILocations> = (record) => {

    const { Title, Text } = Typography;
    const { show } = useNavigation();
    const locationRecord = (record as ILocations)?.countryId !== undefined ? record as ILocations : undefined;

    return (
        <>
            <Divider orientation="left" style={{ color: "blue" }}>Basic Details</Divider>
            <Row gutter={rowGutter}>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>ID</Title>
                    <Text copyable>{record?.id}</Text>
                    {
                        locationRecord &&
                        <>
                            <Title level={5}>Country ID</Title>
                            <Button
                                type="link"
                                onClick={() => { show(RESOURCE_PATH.COUNTRY, locationRecord.countryId) }}
                            >{locationRecord.countryId}</Button>
                        </>
                    }
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Name</Title>
                    <Text copyable>{record?.name}</Text>
                    {
                        locationRecord &&
                        <>
                            <Title level={5}>Display Timezone</Title>
                            <Text>{locationRecord.timezone}</Text>
                        </>
                    }
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Last Updated</Title>
                    <Text>{record ? displayRelativeDate(record.updatedAt) : "Loading"}</Text>
                    {
                        locationRecord &&
                        <>
                            <Title level={5}>IANA Timezone</Title>
                            {locationRecord.timezoneIANA !== undefined ? <Text copyable>{locationRecord.timezoneIANA}</Text> : "---"}
                        </>
                    }
                </Col>
            </Row>
            <Divider orientation="left" style={{ color: "blue" }}>Resourcing Module Configuration</Divider>
            <Row gutter={rowGutter}>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Resourcing Team Group</Title>
                    {record?.resourcingTeamGroup ?
                        <Button
                            type="link"
                            onClick={() => { show(RESOURCE_PATH.GROUPS, record.resourcingTeamGroup) }}
                        >{record.resourcingTeamGroup}</Button> : <Text>---</Text>
                    }
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Resourcing Team DL</Title>
                    {record?.resourcingTeamDL !== undefined ? <Text copyable>{record.resourcingTeamDL}</Text> : "---"}
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Resourcing Team Admin</Title>
                    {record?.resourcingTeamAdmin !== undefined ? <ShowUserCard id={record?.resourcingTeamAdmin} /> : "---"}
                </Col>
            </Row>
            {record && record?.spaces &&
                <>
                    <Divider orientation="left" style={{ color: "blue" }}>Spaces Module Configuration</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Space Admins</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record.spaces.administrators ?? []) ? record.spaces.administrators.map((e, i) => <ShowUserCard key={`spadmn-${i}`} id={e}></ShowUserCard>) : "None"}
                            </Space>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Power Users</Title>
                            <Space wrap split={<Divider type="vertical" />} >
                                {arrayExtensions.validateArray(record.spaces.powerUsers ?? []) ? record.spaces.powerUsers.map((e, i) => <ShowUserCard key={`spwrusrsr-${i}`} id={e}></ShowUserCard>) : "None"}
                            </Space>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Power User Group</Title>
                            {record.spaces.powerUserGroup ?
                                <Button
                                    type="link"
                                    onClick={() => { show(RESOURCE_PATH.GROUPS, record.spaces.powerUserGroup) }}
                                >{record.spaces.powerUserGroup}</Button> : <Text>---</Text>
                            }
                        </Col>
                    </Row>
                    {record.spaces.reservationRules &&
                        <>
                            <Row style={{ marginTop: 15 }} gutter={rowGutter}>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Reservation Timings</Title>
                                    <Text>{"Begin time: " + (record?.spaces?.reservationRules?.beginTime ?? "Not set")}</Text>
                                    <br />
                                    <Text>{"End time: " + (record?.spaces?.reservationRules?.endTime ?? "Not set")}</Text>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Reservation Duration</Title>
                                    <Text>{"Minimum: " + (record?.spaces?.reservationRules?.minReservationDuration ?? "---") + " mins"}</Text>
                                    <br />
                                    <Text>{"Maximum: " + (record?.spaces?.reservationRules?.maxReservationDuration ?? "---") + " mins"}</Text>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title level={5}>Align Reservations</Title>
                                    <Text>{(record?.spaces?.reservationRules?.alignReservations === true ? (record?.spaces?.reservationRules?.alignmentBoundary + " mins") : "No")}</Text>
                                    <Title style={{ marginTop: 15 }} level={5}>Advance Days</Title>
                                    <Text>{(record?.spaces?.reservationRules?.advanceDays ?? "---")}</Text>
                                </Col>
                            </Row>
                        </>}
                </>
            }
            <Divider orientation="left" style={{ color: "blue" }}>Asset Management Module Configuration</Divider>
            <Row gutter={rowGutter}>
                <Col className="gutter-row" {...threeColumnLayout}>
                    <Title level={5}>Asset Admins</Title>
                    <Space wrap split={<Divider type="vertical" />} >
                        {arrayExtensions.validateArray(record?.assetAdmin ?? []) ? record?.assetAdmin?.map((e, i) => <ShowUserCard key={`aadmn-${i}`} id={e}></ShowUserCard>) : "None"}
                    </Space>
                </Col>
                <Col className="gutter-row" {...threeColumnLayout}></Col>
                <Col className="gutter-row" {...threeColumnLayout}></Col>
            </Row>
        </>
    );
}