import { Button, Form, FormProps, Icons, Input, Select, Space, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { ILookup } from "interfaces";
import { IScenarioMetadata } from "interfaces/article";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const ScenarioFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter: () => void,
}> = (props) => {
    const { isLoading, data: metadata } = useOne<IScenarioMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.SCENARIO,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: userProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [
            {
                field: "lastAccessedAt",
                order: "desc"
            },
        ],
        fetchSize: 50
    });

    const { selectProps: serviceLineProps } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.SERVICELINE,
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc"
            },
        ],
        fetchSize: 50
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Scenarios"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Scenarios"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>

                <Form.Item
                    label="Service Lines"
                    name="serviceLineIds"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Service Lines"
                        {...serviceLineProps}
                    />
                </Form.Item>
                <Form.Item
                    label="Value Dimensions"
                    name="valueDimensions"
                >
                    <Select
                        placeholder="Value Dimensions"
                        mode="multiple"
                        options={(metaConfig?.valueDimensions || []).map((m, i) => ({ value: m, label: m }))}
                        allowClear
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Fulfilled via Technology"
                    name="technology"
                >
                    <Select placeholder="Technology"
                        mode="multiple"
                        options={(metaConfig?.technologies || []).map((m, i) => ({ value: m, label: m }))}
                        allowClear>
                    </Select>
                </Form.Item>


                <Form.Item
                    label="Authors"
                    name="authorName"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Authors"
                        {...userProps}
                    />
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icons.FilterOutlined />}
                            onClick={() => {
                                props.onApplyFilter();
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            icon={<Icons.ClearOutlined />}
                            htmlType="submit"
                            onClick={() => { props.formProps.form?.resetFields() }}
                        >
                            Clear
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
    );
}