import { arrayExtensions } from "lib-common";
import { Button, Form, Input, Radio, Select, Spin, Tag } from "@pankod/refine-antd";
import { useUpdate } from "@pankod/refine-core";
import { IListConfig, ITags, TagsReviewForm, IBaseProps } from "interfaces";
import { useEffect, useState } from "react";
import { DATAPROVIDER_REVIEW, RESOURCE_PATH } from "scripts/site";

export declare type TagActionButtonsProps = {
    reviewTag: ITags;
    metadata?: IListConfig;
    onFormSubmission: () => void;
    refetch: () => void;
};
export const TagReview: React.FC<TagActionButtonsProps> = ({ reviewTag, metadata, onFormSubmission, refetch }) => {

    // Populate buttons depending on AI recommendations
    const actions = [];
    const [form] = Form.useForm<TagsReviewForm>();
    const { mutate } = useUpdate<IBaseProps>();
    const [submitButton, switchSubmitButton] = useState(false);

    useEffect(() => {
        // Passed with Category, load only specific category details
        if (reviewTag) {
            form.resetFields();
        }
    }, [reviewTag, form]);

    // Form Submission
    const handleFormSubmission = () => {
        const values = form ? form.getFieldsValue(true) as TagsReviewForm : null;

        // If form is valid then capture the comments
        if (values) {
            // Disable the form submission button
            switchSubmitButton(true);
            mutate({
                dataProviderName: DATAPROVIDER_REVIEW,
                resource: RESOURCE_PATH.REVIEW_TAGS,
                id: values.id,
                values: values,
                successNotification: { key: "notificationKey", message: "Successful", description: `Successfully completed Tag Review`, type: "success" },
                errorNotification: { key: "notificationKey", message: "Error Occured", description: `Unable to complete the Tag Review`, type: "error" }
            },
                {
                    onSuccess: () => {
                        onFormSubmission();
                        refetch();
                        switchSubmitButton(false);
                    },
                    onError: () => {
                        onFormSubmission();
                        switchSubmitButton(false);
                    }
                }
            );
        }
    }

    // Add Default buttons
    actions.push(
        <Radio.Button key="tg-rv-act-accpt" style={{ marginRight: 6, marginBottom: 6 }} value="accept">Accept Tag</Radio.Button>
    );
    actions.push(
        <Radio.Button key="tg-rv-act-crct" style={{ marginRight: 6, marginBottom: 6 }} value="correct">Correct Tag</Radio.Button>
    );
    actions.push(
        <Radio.Button key="tg-rv-act-rjct" style={{ marginRight: 6, marginBottom: 6 }} value="reject">Reject Tag</Radio.Button>
    );
    actions.push(
        <Radio.Button key="tg-rv-act-rjctblc" style={{ marginRight: 6, marginBottom: 6 }} value="rejectblock">Reject & Block Tag</Radio.Button>
    );

    // In case of recommendations
    if (reviewTag && arrayExtensions.validateArray(reviewTag.suggestions)) {
        actions.push(
            <Radio.Button key="tg-rv-act-accpt-sgst" style={{ marginRight: 6, marginBottom: 6 }} value="acceptsuggestion">Accept Suggestion</Radio.Button>
        );
        actions.push(
            <Radio.Button key="tg-rv-act-rjct-sgst" style={{ marginRight: 6, marginBottom: 6 }} value="rejectsuggestions">Reject Suggestion(s)</Radio.Button>
        );
        actions.push(
            <Radio.Button key="tg-rv-act-rjctcrct-sgst" style={{ marginRight: 6, marginBottom: 6 }} value="rejectsuggestionscrct">Reject Suggestion(s) & Correct</Radio.Button>
        );
    }

    return (
        <Spin spinning={submitButton}>
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    id: metadata?.listName || reviewTag.tagType.toLowerCase(),
                    name: reviewTag.tagName
                }}
                autoComplete="off"
                onFinish={handleFormSubmission}
            >
                <Form.Item
                    label="Tag"
                    name="name"
                >
                    <Tag color="blue">{reviewTag.tagName}</Tag>
                </Form.Item>
                <Form.Item
                    label="Tag Type"
                    name="id"
                >
                    <Tag color="purple">{metadata?.name}</Tag>
                </Form.Item>
                <Form.Item
                    label="Select action on Tag"
                    name="operation"
                    rules={[
                        {
                            required: true,
                            whitespace: true
                        }
                    ]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                    >
                        {actions}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.operation !== currentValues.operation}
                >
                    {({ getFieldValue }) =>
                        getFieldValue('operation') === 'acceptsuggestion' || getFieldValue('operation') === 'correct' || getFieldValue('operation') === 'rejectsuggestionscrct' ? (
                            <Form.Item
                                label="Replace with Tag"
                                name="updatedTagName"
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true
                                    }
                                ]}
                            >
                                {getFieldValue('operation') === 'acceptsuggestion' ?
                                    <Select placeholder="Select tag from recommendation ">
                                        {reviewTag.suggestions.map((m, i) => (
                                            <Select.Option value={m} key={i}>{m}</Select.Option>
                                        ))}
                                    </Select> :
                                    <Input placeholder="Provide corrected tag" minLength={3} maxLength={50} type="text" />
                                }
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={submitButton}
                    >
                        Submit Review
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};
