import { arrayExtensions } from "lib-common";
import { Button, Col, Divider, MarkdownField, Row, Space, Tag, Typography } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { GetEntityName } from "components/utils/getEntityName";
import dayjs from "dayjs";
import { ICandidateEvaluation } from "interfaces/resourcing";
import { rowGutter, threeColumnLayout } from "scripts/layout";
import { RESOURCE_PATH, doesExist, formatMS, timeDiff } from "scripts/site";

export const CandidateEvaluationDetails: React.FC<ICandidateEvaluation> = (record) => {

    const { Title, Text } = Typography;
    const { show } = useNavigation();
    return (
        <>
            {record &&
                <>
                    <Divider orientation="left">Basic Details</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>ID</Title>
                            <Text copyable>{record?.id}</Text>
                            <Title level={5}>Name</Title>
                            <Text copyable>{record?.name}</Text>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            <Title level={5}>Resourcing Request</Title>
                            {record.requestId ?
                                <Button
                                    type="link"
                                    style={{ padding: 0 }}
                                    onClick={() => { show(RESOURCE_PATH.RESOURCINGREQUEST, record.requestId) }}
                                >{record.requestId}</Button> : <Text>---</Text>
                            }
                            <Title level={5}>Candidate</Title>
                            <Button
                                type="link"
                                style={{ padding: 0 }}
                                onClick={() => { show(RESOURCE_PATH.CANDIDATE, record.candidateId) }}
                            >
                                <GetEntityName id={record.candidateId} entity={RESOURCE_PATH.CANDIDATE} />
                            </Button>
                        </Col>
                        <Col className="gutter-row" {...threeColumnLayout}>
                            {
                                record && arrayExtensions.validateArray(record.stateManager.assignedTo) &&
                                <>
                                    <Title level={5}>Current Owner(s)</Title>
                                    <Space style={{ marginBottom: 15 }} wrap split={<Divider type="vertical" />} >
                                        {record.stateManager.assignedTo?.map((e, i) =>
                                            <ShowUserCard key={`assgnd-${i}`} id={e}></ShowUserCard>
                                        )}
                                    </Space>
                                </>
                            }
                            <Title level={5}>Evaluation Age (Created in System)</Title>
                            <Text copyable>{record ? formatMS(timeDiff(record.createdAt, new Date().toUTCString()), false, false, 5, true, false) : "Loading"}</Text>
                        </Col>
                    </Row>
                    {doesExist(record.screeningFeedback) &&
                        <>
                            <Divider orientation="left">Screening</Divider>
                            <Row gutter={rowGutter}>
                                <Col className="gutter-row">
                                    <Title level={5}>Screening Feedback</Title>
                                    <MarkdownField value={record.screeningFeedback} />
                                </Col>
                            </Row>
                        </>
                    }
                    {record.availability && arrayExtensions.validateArray(record.availability.availabilitySlots) &&
                        <>
                            <Divider orientation="left">Candidate Availability</Divider>
                            <Row gutter={rowGutter}>
                                {record.availability.availabilitySlots.map((slot, index) =>
                                    <Col className="gutter-row" {...threeColumnLayout}>
                                        <Title level={5}>Slot {index + 1}</Title>
                                        <Text>{dayjs(slot[0]).format('LLLL')}</Text>
                                        <Text> - </Text>
                                        <Text>{dayjs(slot[1]).format('LLLL')}</Text>
                                    </Col>
                                )}
                            </Row>
                            <Row gutter={rowGutter}>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    <Title style={{ marginTop: 15 }} level={5}>Time Zone</Title>
                                    <Text><Tag color="cyan">{record.availability.timezone}</Tag></Text>
                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                    {
                                        record.availability.selectedSlot &&
                                        <>
                                            <Title style={{ marginTop: 15 }} level={5}>Selected Slot</Title>
                                            <Text>{record.availability.selectedSlot}</Text>
                                        </>
                                    }

                                </Col>
                                <Col className="gutter-row" {...threeColumnLayout}>
                                </Col>
                            </Row>
                        </>
                    }
                    {doesExist(record.trFeedback) &&
                        <>
                            <Divider orientation="left">Technical Evaluation Feedback</Divider>
                            <Row gutter={rowGutter}>
                                <Col className="gutter-row">
                                    <Title level={5}>Technical Review Feedback</Title>
                                    <MarkdownField value={record.trFeedback} />
                                </Col>
                            </Row>
                        </>
                    }
                    {doesExist(record.mrFeedback) &&
                        <>
                            <Divider orientation="left">Managerial Evaluation Feedback</Divider>
                            <Row gutter={rowGutter}>
                                <Col className="gutter-row">
                                    <Title level={5}>Managerial Review Feedback</Title>
                                    <MarkdownField value={record.mrFeedback} />
                                </Col>
                            </Row>
                        </>
                    }
                    <Divider orientation="left">Additional Information</Divider>
                    <Row gutter={rowGutter}>
                        <Col className="gutter-row">
                            <Title level={5}>Remarks</Title>
                            {doesExist(record.remarks) ?
                                <MarkdownField value={record.remarks} /> :
                                <Text>---</Text>
                            }
                        </Col>
                    </Row>
                </>
            }
        </>
    )
}