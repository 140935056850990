import { arrayExtensions } from "lib-common";
import { Checkbox, Descriptions, Divider, Icons, Radio, Show, Space, Tabs, Typography } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { HistoryTab } from "components/common/historyTab";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import { DisplayScore } from "components/utils/displayScore";
import { IEvaluation, IGroup, IOption, IQuestion } from "interfaces";
import { DATAPROVIDER_READ } from "scripts/site";

const { Title, Text } = Typography;

export const EvaluationShow: React.FC<IResourceComponentsProps> = () => {

    const { queryResult } = useShow<IEvaluation>({
        dataProviderName: DATAPROVIDER_READ,
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    const getTabs = () => {
        let tabs: any[] = [];

        // Add Details Tab
        tabs.push(
            {
                label: (<span><Icons.InfoOutlined />Details</span>),
                key: "details",
                children: <>
                    <Title level={5}>Name</Title>
                    <Text>{record?.evaluationModel.name}</Text>

                    <Title level={5}>{record?.evaluationModel?.ui.fieldLabel}</Title>
                    <DisplayScore
                        ui={record?.evaluationModel.ui || "" as any}
                        output={record?.evaluationModel.output || "" as any}
                    />

                    <Title level={5}>Version</Title>
                    <Text>{record?.evaluationModel.version}</Text>

                    <Title level={5}>Type</Title>
                    <Text>{record?.evaluationModel.modelType}</Text>

                    <Title level={5}>Total Groups</Title>
                    <Text>{record?.evaluationModel.groups?.length}</Text>

                    <Title level={5}>Last Updated</Title>
                    {displayRelativeDate(record?.updatedAt)}
                </>
            }
        );

        // Add History Tab
        tabs.push(
            {
                label: (<span><Icons.HistoryOutlined />History</span>),
                key: "workflow",
                children: <>
                    {record &&
                        <HistoryTab stateManager={record.stateManager} tabPosition={'left'} />
                    }
                </>
            }
        );

        // Add Model specific Tabs
        arrayExtensions.sortByOrder(record?.evaluationModel.groups || []).forEach((gr, index) => {
            const g = gr as IGroup;
            tabs.push(
                {
                    label: g.name,
                    key: `${g.name}-${index}`,
                    children: <>
                        <Descriptions labelStyle={{ fontWeight: "bold" }} size="small">
                            <Descriptions.Item label="Category Name">
                                {g.name}
                            </Descriptions.Item>
                            {record?.evaluationModel?.ui?.displayCategoryScores ?
                                <Descriptions.Item label="Category Score">
                                    <DisplayScore
                                        override={true}
                                        overrideScore={g.score}
                                        ui={record?.evaluationModel.ui || "" as any}
                                        output={record?.evaluationModel.output || "" as any}
                                    />
                                </Descriptions.Item>
                                : ""
                            }
                            <Descriptions.Item label="Total Questions">
                                {g.questions.length}
                            </Descriptions.Item>
                        </Descriptions>
                        {
                            arrayExtensions.sortByOrder(g.questions).map((q, qi) => {
                                const quesData = q as IQuestion;
                                const maxWeight = quesData.options.reduce((op, item) => op = op > item.weightage ? op : item.weightage, 0);
                                return (
                                    <div className="shwevl" key={"grp - " + index + "ques -" + qi}>
                                        <Divider dashed />
                                        <Space wrap size={"large"} style={{ marginBottom: 20, display: "block" }}>
                                            <Typography.Text style={{ marginRight: 20 }} strong >  {`${qi + 1} - ${quesData.name}`}</Typography.Text>
                                        </Space>
                                        {quesData.multiSelect ?
                                            <Space wrap size={"large"} direction={quesData.optionDisplay} >
                                                {
                                                    arrayExtensions.sortByOrder(quesData.options).map((option, oi) => {
                                                        const optionData = option as IOption;
                                                        return (
                                                            <Checkbox
                                                                defaultChecked={optionData.checked}
                                                                disabled
                                                                key={"option" + oi}
                                                                className={optionData.weightage === maxWeight ? "crctopt" : ""}
                                                            >
                                                                {optionData.name}
                                                            </Checkbox>
                                                        );
                                                    })
                                                }
                                            </Space>
                                            :
                                            <Radio.Group
                                                name={q.id}
                                                disabled
                                                defaultValue={quesData.selectedOption}
                                            >
                                                <Space wrap size={"large"} direction={quesData.optionDisplay}>
                                                    {
                                                        arrayExtensions.sortByOrder(quesData.options).map((option, oi) => {
                                                            const optionData = option as IOption;

                                                            return (
                                                                <Radio
                                                                    key={"option" + oi}
                                                                    value={optionData.name}
                                                                    checked={quesData.selectedOption === optionData.name}
                                                                    className={optionData.weightage === maxWeight ? "crctopt" : ""}
                                                                >
                                                                    {optionData.name}
                                                                </Radio>
                                                            );
                                                        })
                                                    }
                                                </Space>
                                            </Radio.Group>
                                        }
                                        {quesData.comments ?
                                            <Space wrap size={"large"} style={{ marginTop: 20, display: "block" }}>
                                                <Typography.Text style={{ marginRight: 20, color: "GrayText" }}>{quesData.comments}</Typography.Text>
                                            </Space>
                                            :
                                            ""
                                        }
                                    </div>
                                );
                            })
                        }
                    </>
                }
            );
        })
        return tabs;
    }

    return (
        <>
            <Show isLoading={isLoading}
                canDelete={false}
                canEdit={false}
                title={"Evaluation using " + record?.evaluationModel.name}
                headerProps={{
                    extra: <></>
                }}
            >
                <Tabs
                    defaultActiveKey="details"
                    items={getTabs()}
                />
            </Show>
        </>
    );
};
