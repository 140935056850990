class stringExtensionsClass {
    public stringFormat(str: string, ...args: string[]) {
        return str.replace(/{(\d+)}/g, (match, index) => args[index] || '');
    }

    public stringEquals(str1: string, str2: string): boolean {
        return !!(str1 && str1 !== null && (str1.localeCompare(str2, undefined, { sensitivity: 'accent' }) === 0));
    }

    public capitalize(str: string): string {
        if (str) {
            const lower = str.toLocaleLowerCase();
            return str.charAt(0).toUpperCase() + lower.slice(1);
        }
        return str;
    }

    public getGUID(): string {
        var uuidValue: string = "", k: number, randomValue: number;
        for (k = 0; k < 32; k++) {
            randomValue = Math.random() * 16 | 0;

            if (k === 8 || k === 12 || k === 16 || k === 20) {
                uuidValue += "-"
            }
            uuidValue += (k === 12 ? 4 : (k === 16 ? (randomValue & (3 | 8)) : randomValue)).toString(16);
        }
        return uuidValue;
    }

    public getNextAlphabet(index: number, capital: boolean = true) {
        return String.fromCharCode(index + (capital ? 65 : 97));
    }
}

export const stringExtensions = new stringExtensionsClass();