import { Alert, Button, DatePicker, Divider, Form, Icons, Input, Radio, Select, Spin, Tag, notification } from "@pankod/refine-antd";
import { useOne, useUpdate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IBaseProps } from "interfaces";
import { ICandidateEvaluation, ICandidateEvaluationMetadata, IResourcingRequest } from "interfaces/resourcing";
import { useState } from "react";
import { DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, DATAPROVIDER_UPDATE, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export declare type CandidateEvaluationFormProps = {
    evaluationId: string;
    onFormSubmission: () => void;
    refetch?: () => void;
};

export const CandidateEvaluationForm: React.FC<CandidateEvaluationFormProps> = ({ evaluationId, onFormSubmission, refetch }) => {

    const { data: candidateEvaluation, isLoading: candidateLoading } = useOne<ICandidateEvaluation>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.CANDIDATEEVALUATION,
        id: evaluationId,
        queryOptions: {
            enabled: evaluationId.length > 0
        }
    });

    const { data: entityMetadata, isLoading: metadataLoading } = useOne<ICandidateEvaluationMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.CANDIDATEEVALUATION,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const [form] = Form.useForm<ICandidateEvaluation>();
    const { mutate: updateEvaluation } = useUpdate<IBaseProps>();
    const [submitButton, setSubmitButton] = useState(false);
    const metadata = entityMetadata?.data;
    const record = candidateEvaluation?.data;
    const evaluationNotMapped = candidateEvaluation?.data?.requestId?.length === 0;

    // State Variables for Enforcing Validation Rules as per State Action
    // Variable name format - actionAppliedCurrentState
    const [archiveAR, setArchiveAR] = useState(false);
    const [archiveAU, setArchiveAU] = useState(false);
    const [archiveAC, setArchiveAC] = useState(false);
    const [availMRPlanned, setAvailMRPlanned] = useState(false);
    const [resourcingRequestID, setResourcingRequestID] = useState("");

    record?.availability?.availabilitySlots?.forEach((slot, index) => {
        slot[0] = dayjs(slot[0])
        slot[1] = dayjs(slot[0])
    })

    const showAction = (action: string, nextState: string, currentState?: string) => {
        // Don't show the reaasign action on Feedback Form
        if (action === "reassign") {
            return false;
        }
        // Don't show the mrplan action on Feedback Form, if the trfeedback is not provided
        if (action === "planmr" && record && record.trCompleted === false) {
            return false;
        }

        // Don't show the trplan action on Feedback Form, if the trfeedback is provided
        if (action === "plantr" && record && record.trCompleted === true) {
            return false;
        }

        // IN TR Planned state, skip the MR state if the MR is not required, else skip the closed state
        if (record && currentState && currentState === "trplanned" && record.mrRequired === "No" && action === "select") {
            return false;
        }
        if (record && currentState && currentState === "trplanned" && record.mrRequired === "Yes" && action === "selected") {
            return false;
        }

        return true;
    }

    const getActions = () => {
        let actions: any[] = [];
        let currentState = metadata?.states.find(st => st.state === record?.stateManager.state);

        if (currentState && currentState.nextStates.length > 0) {
            currentState.nextStates.forEach((st) => {
                if (showAction(st.action, st.stateId, currentState?.state)) {
                    actions.push(
                        {
                            value: st.action,
                            label: st.buttonText
                        }
                    );
                }
            });
        }
        return actions;
    };

    const saveFeedback = () => {
        setSubmitButton(true);
        form.validateFields().then((values) => {
            // Updated Values
            // Copy remarks as comments for statechange, if provided else use default comment
            let computedValues = { ...values, "comments": form.getFieldValue('remarks') || metadata?.config.defaultComment };

            if (record && ["selected", "select", "reject", "release"].includes(computedValues.stateAction) && record.stateManager.state === "trplanned") {
                computedValues = { ...computedValues, "trCompleted": true };
            }
            if (record && record.stateManager.state === "screening" && evaluationNotMapped && resourcingRequestID.length > 0) {
                computedValues = { ...computedValues, "updateCandidate": true };
            }
            updateEvaluation({
                dataProviderName: DATAPROVIDER_UPDATE,
                resource: RESOURCE_PATH.CANDIDATEEVALUATION,
                id: evaluationId,
                values: computedValues,
                successNotification: { key: "notificationKey", message: "Successful", description: `Successfully updated the candidate evaluation`, type: "success" },
                errorNotification: { key: "notificationKey", message: "Error Occured", description: `Unable to update the candidate evaluation`, type: "error" }
            },
                {
                    onSuccess: () => {
                        onFormSubmission();
                        // Execute Refetch if provided
                        if (refetch) {
                            refetch();
                        }
                        setSubmitButton(false);

                    },
                    onError: () => {
                        onFormSubmission();
                        setSubmitButton(false);
                    }
                }
            );
        }).catch((_errorInfo) => {
            notification.error({
                message: 'Incomplete Details',
                description:
                    'Please validate all the fields and save before submitting. Click save to preserve your responses.'
            });
            setSubmitButton(false);
            return;
        });
    }
    // Form Submission Rules
    const setFormPreValidationRules = (stateAction: string) => {
        // Current state
        let currentState = record?.stateManager.state;
        if (currentState) {
            // Swicth as per current state
            switch (currentState) {
                case "screening":
                    break;
                case "availabilityrequested":
                    // Set validation flags as per action
                    switch (stateAction) {
                        case "archived":
                            setArchiveAR(true);
                            break;
                        default:
                            setArchiveAR(false);
                            break;
                    }
                    break;
                case "availabilityupdated":
                    switch (stateAction) {
                        case "requestavailability":
                        case "archived":
                            setArchiveAU(true);
                            break;
                        default:
                            setArchiveAU(false);
                            break;
                    }
                    break;
                case "availabilityconfirmed":
                    switch (stateAction) {
                        case "archived":
                            setArchiveAC(true);
                            break;
                        default:
                            setArchiveAC(false);
                            break;
                    }
                    break;
                case "trplanned":
                    break;
                case "mrplanned":
                    switch (stateAction) {
                        case "requestavailability":
                            setAvailMRPlanned(true);
                            break;
                        default:
                            setAvailMRPlanned(false);
                            break;
                    }
                    break;
                default:
                    break;
            }
        }
    }

    const { refetch: validateResourcingRequestID } = useOne<IResourcingRequest>({
        dataProviderName: DATAPROVIDER_READ,
        resource: RESOURCE_PATH.RESOURCINGREQUEST,
        id: resourcingRequestID,
        queryOptions: {
            enabled: false,
        }
    });

    const stateFields: any = {}
    stateFields["screening"] =
        <>
            {evaluationNotMapped &&
                <>
                    <Alert
                        message="Candidate is not shortlisted for any resourcing request. To proceed ahead, ensure to tag this candidate against a published resourcing request."
                        type="warning"
                        style={{ marginBottom: "1.2em" }}
                        closable
                    />
                    <Form.Item
                        label="Resourcing Request ID"
                        name="requestId"
                        tooltip="Provide the resourcing request ID for which the candidate is being evaluated. Ensure that the resourcing request is in published state."
                        rules={[
                            {
                                required: true,
                                whitespace: true
                            },
                            {
                                validator: async (_, value: string) => {
                                    if (!value) {
                                        return;
                                    }
                                    const { data } = await validateResourcingRequestID();
                                    if (data && data?.data?.id && data?.data?.stateManager?.state === "published") {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("'Resourcing Request ID' is not found or you don't have access. Please providing a valid ID, ensure that the resourcing request is in published state."),
                                    );
                                }
                            }
                        ]}
                    >
                        <Input placeholder="Provide the GUID of the Resourcing Request" onChange={(event) => setResourcingRequestID(event.target.value)} />
                    </Form.Item>
                </>
            }
            <Form.Item
                label="Screening Feedback"
                name="screeningFeedback"
                tooltip="Provide screening feedback for the candidate"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        max: 3000
                    }
                ]}
            >
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder="Add screening comments for the candidate" />
            </Form.Item>
            <Form.Item
                label="MR round required"
                name="mrRequired"
                tooltip="Specify if MR round is required for this candidate"
                initialValue="Yes"
                rules={[
                    {
                        required: true,
                        whitespace: true
                    }
                ]}
            >
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    name="mrRequired"
                    options={(["Yes", "No"]).map(a => a)}
                />
            </Form.Item>
        </>;

    stateFields["availabilityrequested"] =
        <>
            <Form.Item
                label="Specify Timezone"
                name={["availability", "timezone"]}
                tooltip="Specifcy the timezone of availability slots"
                rules={[
                    {
                        required: !archiveAR,
                        whitespace: true,
                    }
                ]}
            >
                <Select
                    placeholder="Timezone"
                >
                    {metadata?.config?.timezones.map((m, i) => (
                        <Select.Option value={m} key={`tz-${i}`}>{m}</Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Divider orientation="left">Availability Slots</Divider>
            <Form.List
                name={["availability", "availabilitySlots"]}
                rules={[
                    {
                        validator: async (_, values) => {
                            // Skip validation if archive action is applied
                            if (archiveAR) {
                                return Promise.resolve();
                            }
                            if (!values || values.length < 1) {
                                return Promise.reject(new Error('Minimum 1 availability slot is required'));
                            }
                        },
                    },
                ]}
            >
                {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item
                                required={false}
                                key={field.key}
                            >
                                <Form.Item
                                    label="Slot"
                                    noStyle
                                    name={[field.name]}
                                    tooltip="Select start and end time for the slot"
                                    rules={[
                                        {
                                            required: !archiveAR,
                                            message: 'Slot - Time range is required. In time range, end time should be greater than start time',
                                        }
                                    ]}
                                >
                                    <DatePicker.RangePicker
                                        format="YYYY-MM-DD hh:mm A"
                                        style={{ width: '60%' }}
                                        disabledDate={(current) => {
                                            return current && current.valueOf() < Date.now();
                                        }}
                                        showTime={true}
                                        minuteStep={30}
                                        suffixIcon={<Icons.DownOutlined />}
                                        allowClear={false}
                                    />
                                </Form.Item>
                                {fields.length > 1 ? (
                                    <Icons.MinusCircleOutlined
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                        title="Remove Slot"
                                    />
                                ) : null}
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '60%' }}
                                icon={<Icons.PlusOutlined />}
                            >
                                Add Slot
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>;
    stateFields["availabilityupdated"] =
        <>
            <Divider orientation="left">Select Slot</Divider>
            <Form.Item
                label="Slot Timezone"
                name={["availability", "timezone"]}
            >
                <Tag color="cyan">{record?.availability?.timezone}</Tag>
            </Form.Item>
            <Form.Item
                label="Select Availability Slot"
                name={["availability", "selectedSlot"]}
                tooltip="Select the slot from availability slots"
                rules={[
                    {
                        required: !archiveAU,
                        type: "string"
                    }
                ]}
            >
                <Select
                    placeholder="Availability slot"
                >
                    {record?.availability?.availabilitySlots?.map((slot, index) =>
                        <Select.Option value={`${dayjs(slot[0]).format('LLLL')} - ${dayjs(slot[1]).format('LLLL')}`} key={`slt-avl-${index}`}>
                            {dayjs(slot[0]).format('LLLL')} - {dayjs(slot[1]).format('LLLL')}
                        </Select.Option>
                    )}
                </Select>
            </Form.Item>
        </>;
    stateFields["trplanned"] =
        <>
            <Form.Item
                label="Technical Review Feedback"
                name="trFeedback"
                tooltip="Provide Technical Review feedback for the candidate"
                rules={[
                    {
                        required: true,
                        whitespace: true,
                        max: 3000
                    }
                ]}
            >
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder="Add Technical Review comments for the candidate" />
            </Form.Item>
        </>;
    stateFields["mrplanned"] =
        <>
            <Form.Item
                label="Managerial Review Feedback"
                name="mrFeedback"
                tooltip="Provide Manegerial Review feedback for the candidate"
                rules={[
                    {
                        required: !availMRPlanned,
                        whitespace: true,
                        max: 3000
                    }
                ]}
            >
                <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder="Add Managerial Review comments for the candidate" />
            </Form.Item>
        </>;
    return (
        <Spin spinning={metadataLoading || candidateLoading || submitButton}>
            <Form
                layout="vertical"
                initialValues={record}
                autoComplete="off"
                form={form}
                onFinish={() => { form.validateFields() }}
            >
                {record && stateFields[record.stateManager.state]}

                <Form.Item
                    label="Remarks"
                    name="remarks"
                    tooltip="Additional remarks about the candidate"
                    rules={[
                        {
                            required: archiveAR || archiveAU || archiveAC || availMRPlanned,
                            whitespace: true,
                            max: 3000
                        }
                    ]}
                >
                    <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} placeholder="Additional remarks, like - If requesting availability, specify what time works well for panelists or any details which you want to communicate to panelists/resourcing team" />
                </Form.Item>
                <Form.Item
                    label="Mark your decision"
                    name="stateAction"
                    tooltip="Mark your decision and then save."
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            max: 100
                        },
                    ]}
                >
                    <Radio.Group
                        optionType="button"
                        buttonStyle="solid"
                        onChange={(ev) => { setFormPreValidationRules(ev.target.value) }}
                        options={getActions()}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        onClick={() => saveFeedback()}
                        style={{ backgroundColor: "green" }}
                        disabled={submitButton}
                    >
                        <Icons.SaveOutlined />
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Spin >
    );
}