import { arrayExtensions, stringExtensions } from "lib-common";
import { Button, CreateButton, Drawer, Icons, List, Table, Tag, TextField, Typography, getDefaultSortOrder, useTable } from "@pankod/refine-antd";
import { CanAccess, CrudFilters, HttpError, IResourceComponentsProps, useCan, useGetIdentity, useNavigation, useOne } from "@pankod/refine-core";
import { RenderWorkflowModal } from "components/common";
import { EngagementSolutionCount } from "components/engagementsolution/engagementSolutionCount";
import { EngagementSolutionDistinct } from "components/engagementsolution/engagementSolutionDistinct";
import { ShowUserCard } from "components/profile/showUserCard";
import { ProgramSubMenu } from "components/program/subMenu";
import { ScenarioFilter } from "components/scenario/scenarioFilter";
import { LookupButton } from "components/utils/lookupButton";
import dayjs from "dayjs";
import { ILookup, IState, IUser } from "interfaces";
import { IScenario, IScenarioFilterVariables, IScenarioMetadata } from "interfaces/article";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { DATAPROVIDER_CREATE, DATAPROVIDER_LOOKUP, DATAPROVIDER_READ, RESOURCE_PATH, STALE_DURATION, THINK_GEN_AI_LOGO_BLACK, THINK_GEN_AI_LOGO_WHITE, getQueryStringParams } from "scripts/site";
import { getAppTheme } from "scripts/theme";

export const ScenarioList: React.FC<IResourceComponentsProps> = () => {
  const { data } = useGetIdentity();
  const user = data as IUser;

  const { show } = useNavigation();
  const [filterOpen, switchFilterForm] = useState(false);
  const [myScenarios, setMyScenarios] = useState(false);
  const { search } = useLocation();
  const { programId } = getQueryStringParams(search);
  const mode: "List" | "Program" = programId ? "Program" : "List";


  const listFilter: CrudFilters = [
    {
      field: "stateManager.state",
      operator: "eq",
      value: myScenarios ? "" : "active,archived"
    },
    {
      field: "authorName ARRAY_CONTAINS_ANY",
      operator: "eq",
      value: myScenarios ? user.id : ""
    },
    {
      field: "_arrayfields",
      operator: "eq",
      value: "serviceLineIds,authorName,valueDimensions"
    }
  ];

  if (mode === "Program") {
    listFilter.push({
      field: "programId",
      operator: "eq",
      value: programId
    });
  }

  const { data: entityMetadata } = useOne<IScenarioMetadata>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.METADATA,
    id: RESOURCE_PATH.SCENARIO,
    queryOptions: {
      enabled: true,
      staleTime: STALE_DURATION
    }
  });
  const metaConfig = entityMetadata?.data?.config;
  const entityStates = entityMetadata?.data?.states as IState[] ?? [];

  const { tableProps, sorter, tableQueryResult, searchFormProps, filters } = useTable<IScenario, HttpError, IScenarioFilterVariables>({
    dataProviderName: DATAPROVIDER_READ,
    initialSorter: [
      {
        field: "updatedAt",
        order: "desc"
      },
    ],
    permanentFilter: listFilter,
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { q, authorName, serviceLineIds, programId, technology, valueDimensions } = params;
      filters.push({
        field: "q",
        operator: "contains",
        value: q
      });
      filters.push({
        field: "authorName ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: authorName
      });
      filters.push({
        field: "serviceLineIds ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: serviceLineIds
      });
      filters.push({
        field: "technology ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: technology
      });
      filters.push({
        field: "valueDimensions ARRAY_CONTAINS_ANY",
        operator: "eq",
        value: valueDimensions
      });
      filters.push({
        field: "programId",
        operator: "eq",
        value: programId
      });
      return filters;
    }
  });

  function rowClick(record: IScenario) {
    return {
      onClick: () => {
        show(RESOURCE_PATH.SCENARIO, record.id);
      },
    };
  }
  const totalRequests = tableQueryResult?.data?.total ?? 0;
  let isFiltered = filters && filters.length > listFilter.length && filters.some(fltr => fltr.value !== undefined)

  const clearFilters = () => {
    searchFormProps.form?.resetFields();
    searchFormProps.form?.submit();
  }

  const { data: progName } = useOne<ILookup>({
    dataProviderName: DATAPROVIDER_LOOKUP,
    resource: RESOURCE_PATH.PROGRAM,
    id: programId,
    queryOptions: {
      enabled: mode === "Program" && !!programId
    },
  });

  let pageTitle = "";
  let pageTag = "";

  switch (mode) {
    case "Program":
      pageTitle = (progName?.data?.name ?? "");
      pageTag = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Scenario" + (totalRequests !== 1 ? "s" : "");
      break;

    default:
      pageTitle = (tableQueryResult.isLoading ? "Loading" : totalRequests) + " Scenario" + (totalRequests !== 1 ? "s" : "");
      break;
  }

  const canShowEnggSolution = useCan({
    resource: RESOURCE_PATH.ENGAGEMENTSOLUTION,
    action: "show"
  });

  return (
    <List
      headerProps={{
        title: pageTitle,
        tags: <>
          {mode === "Program" && <Tag color={"default"}>
            {pageTag}
          </Tag>}
          <Button key="filter" type="default"
            icon={isFiltered ? <Icons.FilterFilled /> : <Icons.FilterOutlined />}
            style={{ paddingRight: (isFiltered ? 4 : 15) }}
            title={isFiltered ? "You are viewing a filtered list" : (entityMetadata?.data?.config?.search?.textMessages?.buttonText || "Search & Filter")}
            onClick={() => switchFilterForm(true)}>
            Search
            {isFiltered &&
              <>
                <Button
                  type="text"
                  danger
                  title={"Clear Filters"}
                  size="small"
                  style={{ marginLeft: 5 }}
                  onClick={(e) => {
                    clearFilters();
                    e.stopPropagation();
                  }}
                  icon={<Icons.CloseOutlined />}
                >
                </Button>
              </>
            }
          </Button>
        </>,
        extra:
          <>
            <CanAccess resource={RESOURCE_PATH.SCENARIO} action="create" params={{ id: "1", dataProviderName: DATAPROVIDER_CREATE }}>
              <CreateButton icon={<Icons.PlusOutlined />}>Add</CreateButton>
            </CanAccess>
            <Button
              title={myScenarios ? "Show All Scenarios" : "Show My Scenarios"}
              icon={myScenarios ? <Icons.BarsOutlined /> : <Icons.SolutionOutlined />}
              onClick={() => setMyScenarios(!myScenarios)}
            >
              {myScenarios ? "All Scenarios" : "My Scenarios"}
            </Button>
            {entityStates.length > 0 ? RenderWorkflowModal(entityStates) : <></>}
            {mode === "Program" && <>
              <img src={getAppTheme() === "dark" ? THINK_GEN_AI_LOGO_WHITE : THINK_GEN_AI_LOGO_BLACK} alt="Think GenAI" style={{
                height: 30,
                width: 150,
              }}></img>
            </>
            }
          </>
      }}
    >
      {mode === "Program" &&
        <div style={{ marginBottom: 24 }}>
          <ProgramSubMenu programId={programId} selectedResource={RESOURCE_PATH.SCENARIO} ></ProgramSubMenu>
        </div>

      }
      <Table {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          position: ["bottomRight"],
          showTotal: (total => <Typography.Title level={4} style={{ marginRight: 10 }} >Total {total}</Typography.Title>)
        }}
      >
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

        <Table.Column
          dataIndex={["stateManager", "state"]}
          key="stateManager.state"
          title="State"
          onCell={rowClick}
          className="mouseHand"
          sorter
          render={(value) => <TextField value={stringExtensions.capitalize(value)} />}
        />

        <Table.Column
          dataIndex="serviceLineIds"
          key="serviceLineIds"
          title="Service Lines"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <LookupButton noTooltip id={r} resource={RESOURCE_PATH.SERVICELINE} noClick />
              </div>
            ))
          }
        />

        <Table.Column
          dataIndex="valueDimensions"
          key="valueDimensions"
          title="Value Dimensions"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && <Typography.Title
              style={{
                fontWeight: "unset",
                fontSize: "14px",
              }}
              level={5}
              ellipsis={{
                rows: 2,
                tooltip: value.join(", "),
              }}
            >
              {arrayExtensions.validateArray(value ?? [])
                && value.map((e, i) =>
                  <div key={i} style={{ marginBottom: 5 }}>
                    <Tag //style={{ marginBottom: 7 }}
                      color={"blue"}
                      key={`tag-${i}`}>{e}</Tag>
                  </div>
                )}
            </Typography.Title>
          }
        />

        <Table.Column
          dataIndex="technology"
          key="technology"
          title="Technology"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>{r}</div>
            ))
          }
        />

        {canShowEnggSolution.data?.can &&
          <>
            <Table.Column
              dataIndex="id"
              key="usage"
              title="Usage"
              onCell={rowClick}
              className="mouseHand"
              render={(value: string) =>
                <>
                  <div>
                    <EngagementSolutionCount scenarioId={value} />
                    <Typography.Text style={{ color: "GrayText" }}> Implementations in </Typography.Text>
                    <EngagementSolutionDistinct scenarioId={value} fields="engagementId" />
                    <Typography.Text style={{ color: "GrayText" }}> Engagements</Typography.Text>
                  </div>
                </>
              } />
          </>
        }

        <Table.Column
          dataIndex="authorName"
          title="Authors"
          onCell={rowClick}
          className="mouseHand"
          render={(value: string[]) =>
            value && value.map((r, i) => (
              <div key={i} style={{ marginBottom: 5 }}>
                <ShowUserCard id={r} />
              </div>
            ))
          }
        />

        <Table.Column
          dataIndex="publishedDate"
          key="publishedDate"
          title="Published Date"
          render={(value: string) => <>
            {value &&
              <Typography.Text style={{ color: "GrayText" }}>{dayjs(value).format('LLLL')}</Typography.Text>
            }
          </>}
          defaultSortOrder={getDefaultSortOrder("publishedDate", sorter)}
          sorter
          onCell={rowClick}
          className="mouseHand"
        />

      </Table>
      <Drawer
        title={metaConfig?.search?.textMessages?.drawerFormHeader || "Search & Filter"}
        placement="right"
        size="default"
        open={filterOpen}
        onClose={() => { switchFilterForm(false) }}
      >
        <ScenarioFilter formProps={searchFormProps}
          onApplyFilter={() => switchFilterForm(false)} />
      </Drawer>
    </List>
  );
};