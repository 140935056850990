import { Button, Form, FormProps, Icons, Input, Select, Space, useSelect } from "@pankod/refine-antd";
import { useOne } from "@pankod/refine-core";
import { RenderSpinner } from "components/common";
import { ILookup } from "interfaces";
import { IIntakeMetadata } from "interfaces/intake";
import { stringExtensions } from "lib-common";
import { DATAPROVIDER_LOOKUP, RESOURCE_PATH, STALE_DURATION } from "scripts/site";

export const IntakeFilter: React.FC<{
    formProps: FormProps,
    onApplyFilter: () => void,
}> = (props) => {
    const { isLoading, data: metadata } = useOne<IIntakeMetadata>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.METADATA,
        id: RESOURCE_PATH.INTAKE,
        queryOptions: {
            enabled: true,
            staleTime: STALE_DURATION
        }
    });

    const { selectProps: smesList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }]
    });

    const { selectProps: salesExecList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "salesExecutive"
        },
        {
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const { selectProps: deliveryExecList } = useSelect<ILookup>({
        dataProviderName: DATAPROVIDER_LOOKUP,
        resource: RESOURCE_PATH.PEOPLE,
        optionLabel: "nameEmail",
        optionValue: "id",
        filters: [{
            field: "roleIds ARRAY_CONTAINS",
            operator: "eq",
            value: "deliveryExecutive"
        },
        {
            field: "stateManager.state",
            operator: "eq",
            value: "active"
        }],
        sort: [{
            field: "lastAccessedAt",
            order: "desc"
        }],
    });

    const metaConfig = metadata?.data.config;
    return (
        isLoading ? <RenderSpinner message="Loading..." ></RenderSpinner> :
            <Form layout="vertical" {...props.formProps} autoComplete="off">
                <Form.Item
                    label={"Search"}
                    name="q"
                    tooltip={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Intake Request"}
                >
                    <Input
                        placeholder={metaConfig?.search?.textMessages?.searchTextPlaceholder || "Search Intake Request"}
                        prefix={<Icons.SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label="Request Type"
                    name="requestType"
                >
                    <Select placeholder="Request Type" mode="multiple" allowClear showSearch>
                        {metaConfig?.requestType?.map((m) => (
                            <Select.Option value={m.requestTypeName} key={`rt-${m.requestTypeName}`}>{m.requestTypeName}</Select.Option>
                        )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Request Sub Type"
                    name="requestSubType"
                >
                    <Select placeholder="Request Sub Type" mode="multiple" showSearch allowClear>
                        {metaConfig?.requestType?.map((m) => (
                            m.requestSubType?.map((r) => (
                                <Select.Option value={r} key={`rst-${r}`}>{r}</Select.Option>
                            ))
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Assigned To"
                    name="assignedTo"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        placeholder="Intake request assigned to"
                        mode="multiple"
                        {...smesList}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="SME(s)"
                    name="smes"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        placeholder="SME(s)"
                        mode="multiple"
                        {...smesList}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Sales Executive"
                    name="salesExecutive"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Sales Executive"
                        {...salesExecList}
                    />
                </Form.Item>
                <Form.Item
                    label="Delivery Executive"
                    name="deliveryExecutive"
                    tooltip="To look up any missing name type the name of the person and then select"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Delivery Executive"
                        {...deliveryExecList}
                    />
                </Form.Item>
                <Form.Item
                    label="Intake Status"
                    name="state"
                >
                    <Select
                        allowClear
                        mode="multiple"
                        placeholder="Intake status"
                    >
                        {metadata?.data?.states.map((m, i) => (
                            <Select.Option value={m.state} key={i}>{stringExtensions.capitalize(m.state)}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<Icons.FilterOutlined />}
                            onClick={() => {
                                props.onApplyFilter();
                            }}
                        >
                            Apply
                        </Button>
                        <Button
                            icon={<Icons.ClearOutlined />}
                            htmlType="submit"
                            onClick={() => { props.formProps.form?.resetFields() }}
                        >
                            Clear
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
    );
}