import { arrayExtensions } from "lib-common";
import { AntdList, Button, Divider, Icons, Typography, message } from "@pankod/refine-antd";
import { useGetIdentity, useUpdate } from "@pankod/refine-core";
import { ShowUserCard } from "components/profile/showUserCard";
import { displayRelativeDate } from "components/utils/displayRelativeDate";
import dayjs from "dayjs";
import { IUser } from "interfaces";
import { IComment, ISocial } from "interfaces/article";
import { useState } from "react";
import { DATAPROVIDER_SOCIAL, RESOURCE_PATH, greyStyle } from "scripts/site";

export declare type commentsProps = {
    comments?: IComment[];
    socialElementId: string;
};

export const SocialComments: React.FC<commentsProps> = ({
    comments,
    socialElementId
}) => {
    const { data } = useGetIdentity();
    const user = data as IUser;
    const { mutate } = useUpdate<ISocial>();

    const [editMode, setEditMode] = useState<boolean>(true);

    const mutateComment = (body: any) => {
        mutate({
            dataProviderName: DATAPROVIDER_SOCIAL,
            resource: RESOURCE_PATH.SOCIAL,
            id: socialElementId,
            values: body,
            successNotification: false,
            errorNotification: false,
        },
            {
                onSuccess: () => { },
                onError: () => {
                    message.error("Unable to post your comment. Please try again later.");
                }
            }
        );
    };

    const deleteComment = (commentId: string) => {
        commentId && mutateComment({ operation: "deletecomment", commentId: commentId });
    }

    const handleComment = (newCommentText: string, toggleEditMode: boolean, oldCommentText?: string, commentId?: string) => {
        if (newCommentText && newCommentText !== oldCommentText) {
            if (newCommentText.length < 5) {
                message.error("Comment should be atleast 5 characters long.");
            }
            else {
                if (toggleEditMode) {
                    setEditMode(false);
                    setTimeout(() => {
                        setEditMode(true);
                    }, 5000)
                }
                // Update the social Element as per the mode
                mutateComment({ operation: "comment", comment: newCommentText, commentId: commentId });
            }
        }
    }

    return (
        <>
            <Typography.Title level={5}>Post a comment</Typography.Title>
            <Typography.Title level={5}
                style={{
                    insetInlineStart: 0,
                    marginTop: 0,
                    fontWeight: "unset",
                    fontSize: "14px"
                }}
                placeholder="Post your thoughts"
                title="Click to edit the comment. Press Enter to save your comment or Esc to cancel."
                editable={{
                    enterIcon: <Icons.SendOutlined />,
                    text: "",
                    editing: editMode,
                    autoSize: true,
                    maxLength: 300,
                    tooltip: "Press Enter to save your comment or Esc to cancel",
                    onChange: (value) => {
                        handleComment(value, true);
                    },
                    triggerType: ["icon", "text"]
                }}
            >
                Posting the comment...
            </Typography.Title>
            <Divider style={{ marginBottom: 8 }} ></Divider>
            {
                comments && arrayExtensions.validateArray(comments) &&
                <AntdList
                    size="small"
                    // header={<Typography.Title level={5}>Comments</Typography.Title>}
                    itemLayout="vertical"
                    //bordered
                    dataSource={comments.sort((a: IComment, b: IComment) => { return dayjs(b.date).isAfter(dayjs(a.date)) ? 1 : -1 })}
                    pagination={{ position: "bottom", align: "end", pageSize: 10, hideOnSinglePage: true }}
                    renderItem={(item: IComment, index) => {
                        return (
                            <AntdList.Item>
                                <AntdList.Item.Meta
                                    description={
                                        <>
                                            <Typography.Text style={greyStyle}>By </Typography.Text>
                                            <ShowUserCard id={item.by} key={`cmnt-${index}`}></ShowUserCard>
                                            {displayRelativeDate(item.date)}
                                            {(item.by === user.id) && <Button size="small"
                                                style={{ float: "right" }}
                                                icon={<Icons.DeleteOutlined />}
                                                danger
                                                title="Delete the comment"
                                                type="text"
                                                onClick={() => { deleteComment(item.id); }}
                                            />}
                                        </>
                                    }
                                    style={{ marginBottom: 0 }}
                                />
                                <Typography.Paragraph
                                    style={{
                                        fontWeight: "unset",
                                        fontSize: "14px"
                                    }}
                                    title="Click to edit the comment. Press Enter to save your comment or Esc to cancel."
                                    copyable={{
                                        icon: <Icons.CopyOutlined style={{ marginLeft: 5 }} />
                                    }}
                                    editable={(item.by === user.id)
                                        ? {
                                            enterIcon: <Icons.SendOutlined />,
                                            icon: <Icons.EditFilled style={{ marginLeft: 10 }} />,
                                            autoSize: true,
                                            maxLength: 300,
                                            tooltip: "Press Enter to save your comment or Esc to cancel",
                                            onChange: (value) => {
                                                handleComment(value, false, item.body, item.id);
                                            },
                                            triggerType: ["icon", "text"]
                                        }
                                        : false}
                                >{item.body}</Typography.Paragraph>
                            </AntdList.Item>
                        );
                    }}
                />
            }
        </>
    );
}